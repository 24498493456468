<script>
  import ScoreChip from './score-chip.svelte'
  import ScoreAvatar from './score-avatar.svelte'
  import ScoreAnswers from './score-answers.svelte'
  import FLButton from 'fl-components/single/fl-button.svelte'
  import UnderstandScore from './understand-score.svelte'
  // import MapeeCollapse from './mapee-collapse.svelte'

  export let item
  export let diagId
  export let questions

  $: questionData = questions.find(e => e.id === item.name)
</script>

<style>
  .crisis-item-details {
    background-color: var(--surface);
    padding: 0.5rem;
    box-sizing: border-box;
    border-radius: 0.25rem;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 100%;
  }

  .details-header {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 1.5rem 1rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    background-color: var(--surface);
    position: sticky;
    top: -0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    margin-top: -0.5rem;
  }

  .details-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--on-surface);
    opacity: 0.12;
  }

  .actions {
    padding: 0.5rem 1rem;
  }

  .comment {
    padding: 0.5rem 1rem;
  }

  .empty-state {
    background-color: var(--surface);
    padding: 1.5rem 1rem;
    box-sizing: border-box;
    border-radius: 0.25rem;
  }

  .empty-header {
    margin-bottom: 1rem;
  }

  .empty-state section {
    padding: 0.5rem 0;
  }

  .score-0 {
    font-weight: 700;
    color: var(--mapee-orange-800);
  }
  .score-1 {
    font-weight: 700;
    color: var(--mapee-yellow-900);
  }
  .score-2 {
    font-weight: 700;
    color: var(--mapee-green-800);
  }
  .score-3 {
    font-weight: 700;
    color: var(--mapee-blue-800);
  }
</style>

{#if item && item.name}
  <div class="crisis-item-details">
    <header class="details-header">
      <div class="header-content">
        <p class="type-overline">Questão {item.name}</p>
        <h2 class="type-h5">
          {questionData && questionData.exists ? questionData.data().crisisData.synthesis : ''}
        </h2>
      </div>
      <ScoreChip semaphore={item.score}>{item.score.toFixed(1)}</ScoreChip>
    </header>

    <section class="comment type-body-1">
      <p>
        {questionData && questionData.exists ? questionData.data().crisisData.comment : ''}
      </p>
    </section>

    <UnderstandScore {item} {questionData} />

    <ScoreAnswers {item} />

    <!-- <MapeeCollapse {item} /> -->

    <div class="actions">
      <FLButton
        on:click={() => history.pushRouteLink(`/diag?d=${diagId}&g=${item.group}&q=${item.name}`)}>
        <i class="material-icons">edit</i>
        Editar esta questão
      </FLButton>
    </div>
  </div>
{:else}
  <div class="empty-state">
    <header class="empty-header">
      <h2 class="type-h5">Para começar selecione uma questão</h2>
    </header>
    <section>
      <p class="type-subtitle-2">
        Aqui está a performance que você mesmo avaliou, no que diz respeito aos
        fatores que interferem na sobrevivência da empresa frente à Covid-19.
        Observação: nosso resultado é calculado por meio de média geométrica.
      </p>
    </section>

    <section>
      <p class="type-body-1">
        Os escores de
        <span class="score-0">1 a 1,9</span>
        são baixos. De
        <span class="score-1">2,0 a 2,9</span>
        relativamente baixos. De
        <span class="score-2">3 a 3,9</span>
        medianos. E entre
        <span class="score-3">4 e o máximo de 5</span>
        há a indicação que a empresa está bem avaliada, no que se refere a cada
        questão específica.
      </p>
    </section>
  </div>
{/if}
