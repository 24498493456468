<script>
  import { urlData } from 'mapee-stories/router'
  import routeLink from 'mapee-scripts/route-link'
  import { sitemap } from 'mapee-helpers/consts'

  // Stories
  import { tutoStep } from 'mapee-stories/tutorial'

  // Components
  import FLListItem from 'fl-components/single/fl-list-item.svelte'
  import FLWrapperButton from 'fl-components/single/fl-wrapper-button.svelte'

  function startTutorial() {
    history.pushRoute('/panel')
    tutoStep.set(0)
  }
</script>

<style>
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .divider {
    margin: 8px 0px;
    box-sizing: border-box;
    height: 1px;
    background-color: currentColor;
    opacity: 0.12;
  }

  li.selected {
    color: var(--accent);
  }

  li a {
    text-decoration: none;
    color: currentColor;
  }

  .help {
    margin-top: auto;
  }

  a.emphasis > :global(*) {
    outline: 2px solid var(--border);
    outline-offset: -0.5rem;
  }
</style>

<ul>

  {#each sitemap as sm}
    <li class:selected={sm.selected.includes($urlData.segment)}>
      <a use:routeLink href={sm.route} class:emphasis={sm.emphasis}>
        <FLListItem icon={sm.icon} text={sm.text} />
      </a>
    </li>
    {#if sm.divider}
      <li class="divider" aria-label="Divisor" />
    {/if}
  {/each}
  <li class="divider help" aria-label="Divisor" />
  <li>
    <FLWrapperButton fullWidth on:click={startTutorial}>
      <FLListItem icon="help" text="Passo a passo" />
    </FLWrapperButton>
  </li>
</ul>
