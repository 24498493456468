<script>
  import { descriptions } from 'mapee-stories/live-data'
  import { urlData } from 'mapee-stories/router'

  // let loading = false
  let data = {}

  $: if ($urlData.query.g && $descriptions.length > 0) {
    let t = $descriptions.find(d => d.id === $urlData.query.g)
    if (t) {
      data = t.data()
    }
  }
</script>

<h2 class="type-h5">
  {data.type || 'Carregando'}
  <span class="type-body-2">{data.description || ''}</span>
  <!-- <br />
  <small>
    (As questões assinaladas com
    <i class="material-icons">star</i>
    são as do relatório de sobrevivência na crise)
  </small> -->
</h2>
