<script>
  import { onMount, onDestroy } from 'svelte'

  // Stories
  import { urlData } from 'mapee-stories/router'
  import { userMeta } from 'mapee-stories/live-data'

  // Helper
  import { delay } from 'mapee-helpers/timer'
  import {
    generateReport,
    generatePayment,
    checkToProcess,
    titles,
  } from 'mapee-helpers/report-helper'

  // Components
  import FLIndeterminateLoading from 'fl-components/single/fl-indeterminate-loading.svelte'
  import FLButton from 'fl-components/single/fl-button.svelte'

  export const config = {
    title: 'Relatórios',
    noDrawer: true,
    backButton: true,
    callBackButton() {
      history.pushRouteLink('/panel')
    },
  }

  const firestore = firebase.firestore()
  let loadingReports = true
  let reportLoading = {}
  let listener

  let reportList = []
  let diagMeta = null

  async function downloadPerLabelReport(label) {
    reportLoading[label] = true
    await generateReport(diagMeta, label)
    reportLoading[label] = false
  }

  async function downloadReport(index) {
    if (!reportList[index]) return
    reportList[index].loading = true
    await generateReport(diagMeta, reportList[index].type)
    reportList[index].loading = false
  }

  onMount(async () => {
    const project = $urlData.query.p
    const diag = $urlData.query.d
    const reportType = $urlData.query.types
      ? $urlData.query.types.split(/, */)
      : []

    while (!$userMeta.exists) {
      await delay(500)
    }

    diagMeta = await firestore
      .doc(`projects/${project}/diagnostics-meta/${diag}`)
      .get()

    let check = checkToProcess(diagMeta.data(), reportType)

    if (check || 'token' in $urlData.query) {
      let res = await generatePayment(diagMeta, reportType)
      if (!res) return history.replaceRoute('/report_payment_failed')
    }

    listener = diagMeta.ref.onSnapshot(doc => {
      reportList = doc
        .data()
        .reportPermission.filter(e => e !== 'crise' && e !== 'crisis')
        .map(e => ({ loading: false, type: e }))
    })

    loadingReports = false
  })

  onDestroy(() => {
    if (listener) listener()
  })
</script>

<style>
  .title {
    margin: 1.5rem 0px;
    padding: 0px 1.5rem;
  }

  .links-content {
    display: flex;
    flex-direction: column;
  }

  .links-content > :global(*) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--primary);
    color: white;
    text-decoration: none;
    user-select: none;
    position: relative;
    padding: 0.125rem 1rem;
    margin-bottom: 0.25rem;
  }
</style>

<section>
  <h3 class="title">Relatórios:</h3>
  {#if loadingReports}
    <p>
      <FLIndeterminateLoading />
    </p>
  {:else}
    <div class="links-content">
      <FLButton on:click={() => downloadPerLabelReport('free')}>
        {#if reportLoading.free}
          <FLIndeterminateLoading />
        {:else}{titles.free}{/if}
      </FLButton>
      <FLButton on:click={() => downloadPerLabelReport('crisis')}>
        {#if reportLoading.crisis}
          <FLIndeterminateLoading />
        {:else}{titles.crisis}{/if}
      </FLButton>
      {#each reportList as report, index (report.type)}
        <FLButton on:click={() => downloadReport(index)}>
          {#if report.loading}
            <FLIndeterminateLoading />
          {:else}{titles[report.type]}{/if}
        </FLButton>
      {/each}
    </div>
  {/if}
</section>
