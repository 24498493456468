export class TimerTimeout {
  constructor(cb) {
    this.cb = cb
    this.running = false
  }

  start(time) {
    this.stop()
    this.running = setTimeout(this.cb, time)
  }

  stop() {
    if (this.running) {
      clearTimeout(this.running)
    }
  }
}

export class TimerInterval {
  constructor(cb) {
    this.cb = cb
    this.running = false
  }

  start(time) {
    this.stop()
    this.running = setInterval(this.cb, time)
  }

  stop() {
    if (this.running) {
      clearInterval(this.running)
    }
  }
}

export function delay(time) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), time)
  })
}
