import ChartJS from 'chart.js'
import moment from 'moment'
import 'moment/locale/pt-br'

import { chartColors } from 'mapee-helpers/consts'

/**
 *
 * @param {HTMLElement} node
 * @param {*} param
 */
export function chartDirective(node, { diag, value, labels }) {
  let res = [0, 0, 0, 0, 0]
  const diagData = diag.data()
  if (Array.isArray(diagData.questions)) {
    diagData.questions.forEach(q => {
      res[q[value] - 1]++
    })
  }

  let chart = new ChartJS(node, {
    type: 'pie',
    options: {
      maintainAspectRatio: false,
      responsive: false,
      legend: { position: 'right' },
    },
    weight: 4,
    data: {
      labels,
      datasets: [
        {
          data: res,
          backgroundColor: chartColors,
        },
      ],
    },
  })

  let empty = !res.some(e => e > 0)
  if (empty && node.style.display !== 'none') {
    node.style.display = 'none'
  } else if (node.style.display === 'none') {
    node.style.display = 'block'
  }

  return {
    update({ diag, value, labels }) {
      let data = diag.data()
      if (!data) return
      let res = [0, 0, 0, 0, 0]

      if (Array.isArray(data.questions)) {
        data.questions.forEach(q => {
          res[q[value] - 1]++
        })

        chart.data.labels = labels
        chart.data.datasets[0].data = res
        chart.data.datasets[0].backgroundColor = chartColors
        chart.update()

        let empty = !res.some(e => e > 0)
        if (empty && node.style.display !== 'none') {
          node.style.display = 'none'
        } else if (node.style.display === 'none') {
          node.style.display = 'block'
        }
      }
    },
    destroy() {
      chart.destroy()
    },
  }
}

export function computeId(item) {
  return item.id
}
export function computeTitle(item) {
  return item.data().title || 'Diagnóstico sem nome'
}
export function computeSubtitle(item) {
  return moment(item.data().modified.toDate()).format('DD/MM/YYYY')
}
export function computeDate(item) {
  return moment(item.data().modified.toDate())
    .format('DD/MMMM/YYYY')
    .split('/')
    .join(' de ')
}

export function computeProgress(group, diag) {
  let res = 0
  let resSize = 0
  const gId = group.id
  const diagData = diag.data()
  if (Array.isArray(diagData.questions)) {
    let qg = diagData.questions.filter(q => q.group === gId)

    res = qg.filter(q => q.status === 'finished').length
    resSize = qg.filter(q => q.status !== 'inapplicable').length
  }

  return [res, resSize]
}

export function computeScore(group, diag) {
  let res = 0.0
  const gId = group.id
  const diagData = diag.data()
  if (Array.isArray(diagData.questions)) {
    let questions = diagData.questions.filter(
      q => q.group === gId && q.status === 'finished'
    )
    let scores = questions.map(q => Math.cbrt(q.gravity * q.urgency * q.future))
    res = scores.reduce((a, b) => a + b, 0)
    res /= questions.length || 1
  }

  return res.toFixed(1)
}

export function computeProgressAll(diag) {
  let res = 0
  let resSize = 0
  const diagData = diag.data()
  if (Array.isArray(diagData.questions)) {
    let qg = diagData.questions
    res = qg.filter(q => q.status === 'finished').length
    resSize = qg.filter(q => q.status !== 'inapplicable').length
  }

  return [res, resSize]
}

export function computeScoreAll(diag) {
  let res = 0.0
  const diagData = diag.data()
  if (Array.isArray(diagData.questions)) {
    let questions = diagData.questions.filter(q => q.status === 'finished')
    let scores = questions.map(q => Math.cbrt(q.gravity * q.urgency * q.future))
    res = scores.reduce((a, b) => a + b, 0)
    res /= questions.length || 1
  }

  return res.toFixed(2)
}

export function callCreateDiagnostic(projectDoc, questions) {
  let clearQuestions = questions.map(q => ({
    order: q.data().order,
    globalOrder: q.data().globalOrder,
    name: q.data().name,
    group: q.data().group,
    filter: q.data().filter,
    // crisis: q.data().crisis || false,
    // crisisOrder: q.data().crisisOrder || 0,
    status: 'none',
    gravity: 0,
    urgency: 0,
    future: 0,
  }))

  projectDoc.ref
    .collection('diagnostics')
    .add({
      title: '',
      modified: new Date(),
      // profitability: false,
      questions: clearQuestions,
    })
    .then($doc => {
      history.pushRoute('/diag', {
        d: $doc.id,
      })
    })
}
