<script>
  import { onMount, onDestroy } from 'svelte'
  let version = '0.0.0'

  let listener

  onMount(() => {
    if (listener) listener()
    listener = firebase
      .firestore()
      .collection('changelogs')
      .orderBy('version', 'desc')
      .limit(1)
      .onSnapshot(function(query) {
        version = query.docs[0].id
      })
  })

  onDestroy(() => {
    if (listener) listener()
  })
</script>

<style>
  a {
    color: inherit;
    text-decoration: none;
  }

  svg {
    height: 0.9rem;
    position: relative;
    bottom: -0.125rem;
    left: -0.45rem;
    fill: var(--on-surface);
  }
</style>

<p class="type-caption">
  ©MAPEE
  <a href="/changelog">versão {version}</a>
</p>
<p class="type-caption">
  Criado por
  <a
    target="_blank"
    href="https://www.linkedin.com/in/s%C3%A9rgio-ferreira-49272231/">
    Sérgio Ferreira
  </a>
</p>
<p class="type-caption" aria-label="Produzido por Fluido">
  Produzido por
  <a target="_blank" href="https://fluido.design">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 80">
      <path
        d="M146.66 24.94a6.6 6.6 0 0 0 6.67-6.55v-.12a6.67 6.67 0 1 0-13.34 0
        6.61 6.61 0 0 0 6.55 6.67zm110 13c-3.17-3.18-8.58-5.72-15.49-5.72a24.79
        24.79 0 0 0-9.05 1.64 18.54 18.54 0 0 0-11.49 20.38 6.79 6.79 0 0 1-1.85
        4.44A7.07 7.07 0 0 1 214 60.3a6.7 6.7 0 0
        1-4.76-1.67c-1.83-1.66-2-4.13-2-5.72v-42h-11.5v23.02a25.82 25.82 0 0
        0-18.42-.12 17.71 17.71 0 0 0-11.44 13.87 18.68 18.68 0 0 0-.32 3.42
        18.89 18.89 0 0 0 .26 3.1 6.74 6.74 0 0 1-1.82 4.44 7 7 0 0 1-4.84 1.67
        6.71 6.71 0 0 1-4.77-1.67 7.19 7.19 0 0 1-2-5.41V33.36h-11.45v19.89a7.49
        7.49 0 0 1-1.9 5.38 7.06 7.06 0 0 1-4.85 1.67 6.7 6.7 0 0 1-4.76-1.67
        7.2 7.2 0 0 1-2-5.41V33.36H116v19.57c0 1.74-.24 4.13-1.91 5.72a7.09 7.09
        0 0 1-4.84 1.67 6.79 6.79 0 0
        1-4.77-1.67c-1.82-1.67-2-4.13-2-5.72V33.36H91.06v19.57c0 1.74-.24
        4.13-1.91 5.72a7.08 7.08 0 0 1-4.84 1.67 6.74 6.74 0 0
        1-4.76-1.67c-1.83-1.67-2-4.13-2-5.72V29.39c0-6.84-.4-11.52-4.29-15.18S63.42
        10 59.61 10c-4 0-9.52.48-13.51 4.62-3.65 3.92-4 8.51-4
        12.4v6.34H38v9.37h4.13v26.06h11.44V42.73h9v-9.37h-9v-6.05c0-1 .08-3.81
        1.83-5.63a6 6 0 0 1 4.52-1.84 5.91 5.91 0 0 1 4.53 1.76c1.67 1.83 1.67
        4.69 1.67 6.36V55.1c0 3.76.73 7.19 3.88 10.29a14.61 14.61 0 0 0 5.76
        3.41 27 27 0 0 0 8.53 1.2c3.63 0 8.54-.47 12.43-3.14 3.87 2.67 8.75 3.14
        12.51 3.14s8.54-.47 12.45-3.14c3.87 2.67 8.75 3.14 12.51 3.14a26.85
        26.85 0 0 0 8.44-1.2 15.52 15.52 0 0 0 4-1.94 15.46 15.46 0 0 0 4 1.94
        27 27 0 0 0 8.53 1.2c4 0 9.63-.58 13.66-4.1a22.88 22.88 0 0 0 13.55 4.1
        25 25 0 0 0 9.37-1.76 19.94 19.94 0 0 0 4.35-2.43 14.88 14.88 0 0 0 5.31
        3 27.26 27.26 0 0 0 8.53 1.19c4 0 9.64-.58 13.66-4.09A23 23 0 0 0 241.18
        70c6.91 0 12.31-2.54 15.49-5.72a19 19 0 0 0 0-26.39zm-63.84 19.82a8.68
        8.68 0 0 1-6.35 2.54 8.8 8.8 0 0 1-6.47-2.54 9 9 0 0 1-2.62-6.67 9 9 0 0
        1 2.62-6.68 9.38 9.38 0 0 1 12.86 0 9 9 0 0 1 2.63 6.68 9 9 0 0 1-2.64
        6.67zm54.81 0a8.68 8.68 0 0 1-6.35 2.54 8.78 8.78 0 0 1-6.51-2.54 8.9
        8.9 0 0 1-2.62-6.37v-.48a8.82 8.82 0 0 1 9-9 8.73 8.73 0 0 1 6.43 2.54
        8.93 8.93 0 0 1 2.63 6.68 9 9 0 0 1-2.65 6.67z" />
    </svg>
  </a>
</p>
