<script>
  // Components
  import WelcomeCardActions from './welcome-card-actions.svelte'

  // Variables
  export let id
  export let featured
  export let title
  export let description
  export let total

  $: isFirst = id <= 0
  $: isLast = id + 1 >= total
</script>

<style>
  .welcome-card {
    background-color: var(--surface);
    position: relative;
    z-index: 999;
    width: 100%;
    height: 27.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    border-radius: 0.25rem;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }

  @media screen and (min-width: 512px) {
    .welcome-card {
      max-width: 40rem;
    }

    header {
      max-width: 18rem;
    }
    .welcome-card-copy {
      max-width: 19rem;
      margin-left: auto;
    }
    article {
      width: 100%;
      max-width: 35rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .welcome-card-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: opacity;
    animation: enterCardContents 210ms 90ms both
      cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  article {
    position: relative;
    will-change: opacity;
    animation: enterCardContents 210ms 90ms both
      cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  @keyframes enterCardContents {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  header {
    padding-top: 6.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: var(--on-surface);
  }

  .welcome-card-copy {
    padding: 1rem 1.5rem;
    color: var(--on-surface-secondary);
  }
</style>

<div class="welcome-card">
  <div aria-hidden="true" class="welcome-card-bg">
    <slot />
  </div>
  <article>
    <header>
      {#if featured}
        <h1 class="sr-only">Tutorial. Passo {id + 1} de 5. {title}</h1>
        <h1 class="type-h3" aria-hidden="true">
          {@html title}
        </h1>
      {:else}
        <h2 class="sr-only">Tutorial. Passo {id + 1} de 5. {title}</h2>
        <h2 class="type-h6" aria-hidden="true">{title}</h2>
      {/if}
    </header>
    <div class="welcome-card-copy">
      <p class="type-body-1">
        {@html description}
      </p>
    </div>
  </article>
  <WelcomeCardActions
    on:skip-welcome
    on:next-card
    on:prev-card
    id={id + 1}
    first={isFirst}
    last={isLast}
    {total} />
</div>
