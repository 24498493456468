<script>
  import { onMount } from 'svelte'
  import lottieDirective from 'mapee-scripts/lottie'

  export const config = {
    title: 'Feedback',
  }

  const lottieOptions = {
    path: '/animations/thanks.json',
    renderer: 'svg',
    loop: true,
    autoplay: true,
    name: 'Thanks',
  }
</script>

<style>
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .animation {
    width: 16rem;
    height: 16rem;
  }
</style>

<section>
  <div class="animation" use:lottieDirective={lottieOptions} />
  <p class="type-h4">Obrigado por entrar em contato</p>
</section>
