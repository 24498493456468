<script>
  import { slide } from 'svelte/transition'

  import CollapseTrigger from './fl-collapse-panel/fl-collapse-trigger.svelte'

  export let list = []
  export let autoFocus = false
  export let computeId = data => data.id || null
  export let computeTitle = data => data.title || 'Sem titulo'
  export let computeSubtitle = data => data.subtitle || null
  export let expandElement = $index => {
    if (index !== $index) return $index >= 0 ? $index : -1
    else return -1
  }

  let index = -1
</script>

<style>
  ul {
    list-style-type: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-inline-start: 0px;
  }

  li {
    display: block;
    box-sizing: border-box;
    background-color: var(--surface, #fff);
    margin: 0px;
    position: relative;
    box-sizing: border-box;
    transition: margin 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  }

  li:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  li:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  li::before {
    top: -1px;
    left: 0px;
    right: 0px;
    height: 1px;
    content: '';
    opacity: 1;
    position: absolute;
    box-sizing: border-box;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.12);
  }
  li:first-child::before,
  li.expanded::before,
  li.expanded + li::before {
    display: none;
  }

  .collapse-content {
    display: block;
    padding: 8px 0px 24px;
    box-sizing: border-box;
  }

  li.expanded {
    margin: 16px 0px;
  }

  li .collapse-actions > :global(*) {
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  li .collapse-actions:first-child {
    border-bottom: 1px solid #e8e8e8;
  }
  li .collapse-actions:last-child {
    border-top: 1px solid #e8e8e8;
  }

  li .collapse-actions:empty {
    display: none;
  }
</style>

<ul rule="listbox">
  {#each list as el, i (computeId(el))}
    <li class:expanded={index === i}>
      <CollapseTrigger
        index={i}
        size={list.length}
        autoFocus={autoFocus && i === 0}
        title={computeTitle(el)}
        subtitle={computeSubtitle(el)}
        expanded={index === i}
        on:click={() => (index = expandElement(i, el))} />
      {#if index === i}
        <div transition:slide|local role="group">
          <div class="collapse-actions">
            <slot name="actions-top" />
          </div>
          <section class="collapse-content">
            <slot item={el} index={i} />
          </section>
          <div class="collapse-actions">
            <slot name="actions" />
          </div>
        </div>
      {/if}
    </li>
  {/each}
</ul>
