<script>
  import { onMount, onDestroy } from 'svelte'
  import { config, screen } from 'mapee-stories/main'

  // Components
  import DrawerCopyright from './drawer-copyright.svelte'
  import DrawerLinks from './drawer-links.svelte'

  function externalClick() {
    if ($screen.drawerOpen) $screen.drawerOpen = false
  }

  $: if ($screen.drawerOpen) {
    window.addEventListener('mousedown', externalClick)
  } else {
    window.removeEventListener('mousedown', externalClick)
  }
</script>

<style>
  header {
    display: flex;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    margin-block-end: 1rem;
    flex: 0 0 auto;
  }

  nav {
    flex: 1 0 auto;
  }

  img {
    margin: auto;
    height: 5rem;
  }

  aside {
    margin-block-start: auto;
    padding: 1rem;
  }

  @supports (padding: max(0px)) {
    aside {
      padding-bottom: max(1rem, env(safe-area-inset-bottom));
      padding-left: max(1rem, env(safe-area-inset-left));
    }
    header {
      padding-top: max(0.5rem, env(safe-area-inset-top));
      padding-left: max(1rem, env(safe-area-inset-left));
    }
  }
</style>

<header>
  <img src="/images/logo-mapee-clean.svg" alt="Marca do MAPEE" />
</header>
<nav>
  <DrawerLinks />
</nav>
<aside>
  <DrawerCopyright />
</aside>
