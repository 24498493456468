<script>
  import { fade, fly } from 'svelte/transition'

  // Components
  import FLIconButton from 'fl-components/single/fl-icon-button.svelte'

  export let title = 'Alerta!'
  export let subtitle = ''
  export let open = false
  export let locked = false
</script>

<style>
  .modal-scrim {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.38);
    z-index: 1999;
  }

  .modal {
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    max-height: calc(100% - 2rem);
    max-width: 512px;
    min-width: 320px;
    width: calc(100% - 2rem);
    margin: 0 auto;
    border-radius: 0.25rem;
    background-color: var(--surface, #fff);
    overflow-y: auto;
    z-index: 2000;
  }

  header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 1rem;
    min-height: 3rem;
    border-bottom: 1px solid var(--divider, #e8e8e8);
  }

  header > :first-child {
    flex-grow: 1;
  }

  header h1 {
    margin-bottom: -0.375rem;
  }

  header span {
    opacity: 0.87;
  }

  section {
    display: block;
    padding: 1.5rem 1rem;
    box-sizing: border-box;
  }

  footer > :global(*) {
    margin-top: 15px;
    padding: 16px 16px 16px 8px;
    border-top: 1px solid var(--divider, #e8e8e8);
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  footer:empty {
    display: none;
  }
</style>

{#if open}
  <div
    class="modal-scrim"
    transition:fade|local
    on:click={() => {
      if (!locked) open = false
    }} />
  <div class="modal" transition:fly|local={{ y: -500 }}>
    <form on:submit|preventDefault|stopPropagation>
      <header>
        <div>
          <h1 class="type-h6">{title}</h1>
          {#if subtitle}
            <span class="type-subtitle-2">{subtitle}</span>
          {/if}
        </div>
        {#if !locked}
          <FLIconButton icon="close" on:click={() => (open = false)} />
        {/if}
      </header>
      <section>
        <slot />
      </section>
      <footer>
        <slot name="actions" />
      </footer>
    </form>
  </div>
{/if}
