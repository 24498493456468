<script>
  import { fade } from 'svelte/transition'
  import { config, screen } from 'mapee-stories/main'
</script>

<style>
  .scrim {
    display: block;
    background-color: rgba(0, 0, 0, 0.38);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
  }
</style>

{#if !$config.noDrawer && $screen.drawerOpen}
  <div
    class="scrim"
    transition:fade|local
    on:click={() => ($screen.drawerOpen = false)} />
{/if}
