<script>
  import {
    gravityChartLabels,
    urgencyChartLabels,
    futureChartLabels,
  } from 'mapee-helpers/consts'

  // Components
  import ScoreChip from './score-chip.svelte'
  import ScoreAvatar from './score-avatar.svelte'
  import WrapperButton from 'fl-components/single/fl-wrapper-button.svelte'
  export let item

  let gravityIsExpanded = false
  let urgencyIsExpanded = false
  let futureIsExpanded = false

  function toggleGravity() {
    gravityIsExpanded = !gravityIsExpanded
    urgencyIsExpanded = false
    futureIsExpanded = false
  }
  function toggleUrgency() {
    gravityIsExpanded = false
    urgencyIsExpanded = !urgencyIsExpanded
    futureIsExpanded = false
  }
  function toggleFuture() {
    gravityIsExpanded = false
    urgencyIsExpanded = false
    futureIsExpanded = !futureIsExpanded
  }
</script>

<style>
  .score-answers {
    padding: 0.5rem 1rem;
  }

  .answers {
    margin: 0.5rem -0.5rem;
  }

  .answers > :global(*) {
    margin: 0.125rem;
  }
</style>

<section class="score-answers">
  <h3 class="type-subtitle-2">Suas respostas</h3>
  <div class="answers">
    <WrapperButton on:click={toggleGravity} let:focus>
      <ScoreChip {focus} semaphore={item.gravity}>
        <div slot="avatar">
          <ScoreAvatar>G</ScoreAvatar>
        </div>
        {#if gravityIsExpanded}
          {gravityChartLabels[item.gravity - 1] || 'Sem avaliação'}
        {:else}{item.gravity}{/if}
      </ScoreChip>
    </WrapperButton>

    <WrapperButton on:click={toggleUrgency} let:focus>
      <ScoreChip {focus} semaphore={item.urgency}>
        <div slot="avatar">
          <ScoreAvatar>U</ScoreAvatar>
        </div>
        {#if urgencyIsExpanded}
          {urgencyChartLabels[item.urgency - 1] || 'Sem avaliação'}
        {:else}{item.urgency}{/if}
      </ScoreChip>
    </WrapperButton>

    <WrapperButton on:click={toggleFuture} let:focus>
      <ScoreChip {focus} semaphore={item.future}>
        <div slot="avatar">
          <ScoreAvatar>F</ScoreAvatar>
        </div>
        {#if futureIsExpanded}
          {futureChartLabels[item.future - 1] || 'Sem avaliação'}
        {:else}{item.future}{/if}
      </ScoreChip>
    </WrapperButton>
  </div>
</section>
