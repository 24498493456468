import { readable, derived, writable } from 'svelte/store'
import { user } from './main'

const firestore = firebase.firestore()

export const descriptions = readable([], set => {
  return firebase
    .firestore()
    .collection('descriptions')
    .orderBy('order')
    .onSnapshot(query => {
      set(query.docs)
    })
})

export const questions = readable([], set => {
  return firebase
    .firestore()
    .collection('questions')
    .orderBy('globalOrder')
    .onSnapshot(query => {
      set(query.docs)
    })
})

export const crisisQuestions = derived(questions, $questions =>
  $questions
    .filter(q => q.data().crisis)
    .sort((a, b) => a.data().crisisOrder - b.data().crisisOrder)
)

export const tutorial = readable([], set => {
  return firebase
    .firestore()
    .collection('tutorials')
    .orderBy('order')
    .onSnapshot(query => {
      set(query.docs)
    })
})

export const userData = writable({})
export const userMeta = writable({})

user.subscribe($user => {
  let ud
  let um

  if ($user && $user.uid) {
    if (ud) ud()
    if (um) um()
    ud = firestore.doc(`users/${$user.uid}`).onSnapshot($doc => {
      if (!$doc.exists) $doc.ref.set({})
      userData.set($doc || {})
    })
    um = firestore.doc(`users-meta/${$user.uid}`).onSnapshot($doc => {
      userMeta.set($doc || {})
    })
  } else {
    if (ud) {
      ud()
      ud = false
    }
    if (um) {
      um()
      um = false
    }
    userData.set({})
    userMeta.set({})
  }
})

export const projectDoc = readable(null, set => {
  let unsub
  return user.subscribe($user => {
    if (unsub) unsub()

    if ($user && $user.uid) {
      unsub = firestore
        .collection(`projects-meta`)
        .where('team', 'array-contains', $user.uid)
        .limit(1)
        .onSnapshot(async $query => {
          if ($query.docs[0]) {
            let mainProject = await firestore
              .doc(`projects/${$query.docs[0].id}`)
              .get()

            if (!mainProject) {
              firestore
                .doc(`projects/${doc.id}`)
                .set({})
                .then(doc => {
                  set(doc)
                })
            } else {
              set(mainProject)
            }
          } else {
            firestore
              .collection(`projects-meta`)
              .add({ team: [$user.uid], admins: [$user.uid] })
          }
        })
    }
  })
})
