<script>
  // stores
  import { route, urlData } from 'mapee-stories/router'
  import { config } from 'mapee-stories/main'
</script>

<style>
  .route-content {
    grid-area: content;
    box-sizing: border-box;
    overflow-x: hidden;
    flex-grow: 1;
    padding: 2rem 0;
    max-width: 77rem;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 375px) {
    .route-content {
      width: 100%;
      padding: 2rem 1rem;
    }
  }
  @media screen and (min-width: 30rem) {
    .route-content {
      padding: 2rem 3.5rem;
    }
  }
</style>

<div class="route-content">
  <svelte:component this={$route} bind:config={$config} />
</div>
