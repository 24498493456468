firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
})
firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch(() => {
    console.error('Não foi possível ativar modo persistente!!!')
  })

firebase.__CLIENT_ID =
  '1055643998321-cnbfcg0d1q10o7pastkfelm2rjibcepb.apps.googleusercontent.com'

firebase.auth().useDeviceLanguage()
