<script>
  import { onMount, onDestroy } from 'svelte'
  import moment from 'moment'

  import {
    computeId,
    computeTitle,
    computeSubtitle,
  } from 'mapee-helpers/diagnostic-helper'

  import { callReportsProcess } from 'mapee-helpers/report-helper'

  // Components
  import QuestionGroupItem from 'mapee-components/single/question-group-item.svelte'
  import ActionDataTable from 'mapee-components/complex/action-data-table.svelte'
  import Modal from 'fl-components/simple/fl-modal.svelte'
  import Collapse from 'fl-components/simple/fl-collapse-panel.svelte'
  import FLButton from 'fl-components/single/fl-button.svelte'
  import FLIndeterminateLoading from 'fl-components/single/fl-indeterminate-loading.svelte'
  import FormReportGen from 'mapee-components/simple/form-report-gen.svelte'

  // Stores
  import { urlData } from 'mapee-stories/router'
  import { descriptions, projectDoc } from 'mapee-stories/live-data'

  export const config = {
    title: 'Resultados',
  }

  const firestore = firebase.firestore()
  let loading = false
  let myDiagnostics = []
  let listenerDiagnostics
  let openMoreMenu = false

  let diagToReport = null
  let reportDiagModal = false
  let reportDiagTypesFlag = []
  let reportDiagCost = 0
  let waitReportModal = false
  let errorReportModal = false

  $: if ($projectDoc && !loading) {
    loadDiagnostics()
  }

  async function loadDiagnostics() {
    if (listenerDiagnostics) listenerDiagnostics()
    listenerDiagnostics = $projectDoc.ref
      .collection('diagnostics')
      // .where('profitability', '==', false)
      .orderBy('modified', 'desc')
      .onSnapshot(($snap) => {
        myDiagnostics = $snap.docs
        loading = true
      })
  }

  function autoFocusDirective(node) {
    node.focus()
    return function() {}
  }

  onDestroy(() => {
    if (listenerDiagnostics) listenerDiagnostics()
  })
</script>

<Modal bind:open={errorReportModal} title="Erro">
  <p>Algo inesperado aconteceu.</p>
  <p>
    Tente novamente mais tarde. Caso o erro persista entre em contato com o
    suporte
  </p>
</Modal>

<Modal
  locked
  bind:open={waitReportModal}
  title="Aguarde enquanto processamos..."
>
  <div class="box-center">
    <FLIndeterminateLoading />
  </div>
</Modal>

<Modal
  bind:open={reportDiagModal}
  title="Gerando relatório"
  subtitle={diagToReport ? diagToReport.data().title : ''}
>
  <h3 class="type-subtitle-2">
    Os relatórios estão sendo gerados gratuitamente
  </h3>
  <FormReportGen
    diag={diagToReport}
    bind:typesFlag={reportDiagTypesFlag}
    bind:cost={reportDiagCost}
  />
  <div slot="actions">
    <FLButton
      text
      on:click={() => {
        reportDiagModal = false
        waitReportModal = true
        callReportsProcess(diagToReport, reportDiagTypesFlag).catch((err) => {
          console.error(err)
          waitReportModal = false
          errorReportModal = true
        })
      }}
    >
      gerar relatórios
    </FLButton>
    <span class="report-cost">
      <span class="type-caption">Subtotal:</span>
      <span class="type-h5">R$: {reportDiagCost.toFixed(2)}</span>
    </span>
  </div>
</Modal>

<section tabindex="-1" use:autoFocusDirective class:load={loading}>
  <h2 class="title type-h5">Resultados dos questionários</h2>
  <p class="secondary">
    Selecione um resultado abaixo e obtenha seus relatórios.
  </p>
  <Collapse
    let:item
    list={myDiagnostics}
    {computeId}
    {computeTitle}
    {computeSubtitle}
  >
    <div class="action-map-content">
      <!-- <header>
        <p>
          Encontre as prioridades por áreas apagando/clicando no
          <i class="material-icons rotate-icon">add_circle</i>
          , ou digitando: Gestão Estratégica, Comercial, Administrativa,
          Financeira, Recursos Humanos, Tecnologia e Produção.
        </p>
        <p>
          Marque as questões que deseja acompanhar para gerar relatórios e plano
          de ação. Você pode classificar a lista abaixo por pontuação, ordem da
          pergunta, gravidade, urgência ou futuro, basta clicar nos títulos.
        </p>
      </header> -->
      <div class="table-wrapper">
        <ActionDataTable diag={item} />
      </div>
    </div>
    <div slot="actions-top">
      <p class="expiration-alert type-body">
        Seus relatórios ficam disponíveis por 30 dias, fique livre para fazer
        modificações
      </p>
      <FLButton
        on:click={() => {
          reportDiagModal = true
          diagToReport = item
        }}
      >
        relatórios
      </FLButton>
    </div>
  </Collapse>
</section>

<style>
  section {
    font-size: 1rem;
    padding-bottom: 4rem;
    outline: none;
    opacity: 0;
    transition: opacity 0.3s;
  }

  section.load {
    opacity: 1;
  }

  .title {
    margin: 1.5rem 0px;
    padding: 0px 1.5rem;
  }
  .title + p {
    margin-left: 1.5rem;
  }

  .box-center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    color: var(--accent);
  }

  .table-wrapper {
    -webkit-overflow-scrolling: touch;
    height: 30rem;
    overflow-y: scroll;
  }

  .expiration-alert {
    margin-right: 1rem;
    text-align: right;
    color: var(--on-surface-secondary);
  }

  .report-cost {
    margin-right: auto;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .report-cost > :last-child {
    opacity: 0.87;
    padding-left: 0.5rem;
  }

  .secondary {
    color: var(--on-surface-secondary);
    margin-bottom: 1rem;
  }
</style>
