<script>
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let index
  export let size
  export let title
  export let subtitle
  export let expanded
  export let autoFocus = false

  function autoFocusDirective(node) {
    if (autoFocus) node.focus()
    return function() {}
  }
</script>

<style>
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4rem;
    padding: 0 1.5rem;
    border: none;
    background-color: var(--surface);
    color: var(--on-surface);
    text-align: inherit;
    transition: height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  time {
    color: var(--on-surface-hint);
  }

  div {
    display: flex;
    width: 100%;
  }

  span {
    flex-basis: 30%;
    padding-right: 1.5rem;
    box-sizing: border-box;
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    flex: 0 0 3rem;
    border-radius: 100000000px;
    color: var(--on-surface-secondary);
    cursor: pointer;
  }

  i:hover {
    background-color: var(--on-surface-hint);
  }
</style>

<button
  on:click={() => dispatch('click')}
  role="option"
  aria-posinset={index}
  aria-setsize={size}
  use:autoFocusDirective>
  <div class="type-subtitle">
    <span>{title}</span>
    <time aria-label="Atualizado em {subtitle}" datetime={subtitle}>
      {subtitle}
    </time>
  </div>
  <i class="material-icons" aria-hidden="true">
    {expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
  </i>
</button>
