<script>
  // Components
  import DiagStepper from './diag-stepper.svelte'
  import FlButton from 'fl-components/single/fl-button.svelte'
  import FlIconButton from 'fl-components/single/fl-icon-button.svelte'
  import TutorialBox from 'mapee-components/single/tutorial-box.svelte'
  import Modal from 'fl-components/simple/fl-modal.svelte'

  // Stores
  import { questions } from 'mapee-stories/live-data'
  import { urlData } from 'mapee-stories/router'

  export let doc

  let currentQuestion = {}
  let currentQuestionData = {}
  let endAlert = false
  let questionDescription = false
  let openedQuestion = false

  $: isAnswered =
    currentQuestionData.gravity > 0 &&
    currentQuestionData.urgency > 0 &&
    currentQuestionData.future > 0

  $: if (doc && $urlData.query.q && $questions && $questions.length > 0) {
    openedQuestion = false
    currentQuestion = $questions
      .find((q) => q.data().name === $urlData.query.q)
      .data()
    currentQuestionData = doc
      .data()
      .questions.find((q) => q.name === $urlData.query.q)
  }

  function receiveAnswers({ detail }) {
    currentQuestionData = detail
  }

  function callStoreAnswer(status, clean = false) {
    currentQuestionData.status = status
    if (clean) {
      currentQuestionData.gravity = 0
      currentQuestionData.urgency = 0
      currentQuestionData.future = 0
    }
    let questions = [].concat(doc.data().questions)

    let index = doc
      .data()
      .questions.findIndex((q) => q.name === currentQuestionData.name)
    questions[index] = currentQuestionData
    let toStore = { questions, modified: new Date() }

    let nextQuestion = false

    if ($urlData.query.filter) {
      do {
        index++
        nextQuestion = questions[index]
      } while (
        nextQuestion &&
        !nextQuestion.filter.includes($urlData.query.filter)
      )
    } else {
      nextQuestion = questions[index + 1]
    }
    // if (doc.data().profitability) {
    //   let pl = questions
    //     .filter(q => q.crisis)
    //     .sort((a, b) => a.crisisOrder - b.crisisOrder)
    //   index = pl.findIndex(q => q.name === currentQuestionData.name)
    //   nextQuestion = pl[index + 1]
    // }

    if (nextQuestion) {
      toStore.lastQuestion = nextQuestion.name
    } else {
      // if (doc.data().profitability) {
      //   endCrisisAlert = true
      // } else {
      //   }
      endAlert = true
    }
    doc.ref.set(toStore, { merge: true })

    if (nextQuestion) {
      let q = {
        d: $urlData.query.d,
        q: nextQuestion.name,
        g: nextQuestion.group,
      }
      if ($urlData.query.filter) {
        q.filter = $urlData.query.filter
      }
      history.pushRoute('/diag', q)
    }
  }
</script>

<Modal bind:open={endAlert} title="Diagnostico concluído!">
  <p>Parabéns, você concluiu esse diagnostico.</p>
  <p>
    Você pode editar as informações a qualquer momento acessando painel de
    controle e verificar os resultados no mapa para ação.
  </p>
  <div>
    <FlButton text on:click={() => history.pushRoute('/actionmap')}>
      ver resultados
    </FlButton>
  </div>
</Modal>

<!-- <Modal bind:open={endCrisisAlert} title="Diagnostico concluído!">
  <p>Parabéns, você concluiu o diagnostico de lucratividade.</p>
  <p>
    Você pode editar as informações a qualquer momento e verificar os resultados
    acessando a pagina de lucratividade.
  </p>
  <div>
    <FlButton text on:click={() => history.pushRoute('/crisis')}>
      ver resultados
    </FlButton>
  </div>
</Modal> -->

<Modal bind:open={questionDescription} title="">
  <p>
    {currentQuestion.description || 'Nossa equipe está trabalhando na descrição dessa questão'}
  </p>
</Modal>

<div>
  <header>
    <h1 class="type-body">
      {currentQuestion.name || ''} - {currentQuestion.question || ''}
    </h1>
    <FlIconButton icon="info" on:click={() => (questionDescription = true)} />
  </header>
  <TutorialBox emphasis step="3" />
  {#if openedQuestion}
    <main>
      <DiagStepper on:click={receiveAnswers} data={currentQuestionData} />
    </main>
    <section>
      <FlButton outline isAccent on:click={() => (openedQuestion = false)}>
        Voltar
      </FlButton>
      <FlButton
        disabled={!isAnswered}
        on:click={() => {
          openedQuestion = false
          callStoreAnswer('finished')
        }}
      >
        Concluir
      </FlButton>
    </section>
  {:else}
    <section class="column">
      <FlButton
        outline
        isAccent
        on:click={() => callStoreAnswer('not-important', true)}
      >
        Não é importante na empresa
      </FlButton>
      <FlButton
        outline
        isAccent
        on:click={() => callStoreAnswer('inapplicable', true)}
      >
        Não se aplica ao negócio
      </FlButton>
      <FlButton
        outline
        isAccent
        on:click={() => callStoreAnswer('jumped', true)}
      >
        Responder depois
      </FlButton>
      <FlButton on:click={() => (openedQuestion = true)}>
        Responder agora
      </FlButton>
    </section>
  {/if}
  <TutorialBox emphasis step="4" />
  <TutorialBox emphasis step="5" />
</div>

<style>
  div {
    background-color: var(--surface, #fff);
    color: var(--on-surface);
    border-radius: 0.25rem;
  }

  header {
    display: flex;
    padding: 1.5rem;
  }

  h1 {
    margin-block-end: 0px;
  }

  main {
    padding-bottom: 1.5rem;
  }

  section {
    position: relative;
    padding: 1rem;
    box-sizing: border-box;
    user-select: none;
    display: flex;
  }

  section > :global(* + *) {
    margin-left: 1rem;
  }

  section.column {
    flex-direction: column;
    align-items: flex-start;
  }

  section.column > :global(* + *) {
    margin-left: 0;
    margin-top: 1rem;
  }
  /* footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    opacity: 0.12;
    background-color: currentColor;
  } */

  /* .secondary {
    padding: 0 1.5rem;
    color: var(--on-surface-secondary);
  } */
</style>
