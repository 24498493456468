<script>
  export let icon = ''
  export let text
</script>

<style>
  .fl-list-item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    outline: none;
    text-decoration: none;
    color: currentColor;
    box-sizing: border-box;
  }
  i {
    margin-right: 2rem;
  }
  .fl-list-item:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
</style>

<div class="fl-list-item">
  <i class="material-icons" aria-hidden="true">{icon}</i>
  <span class="type-subtitle">{text}</span>
</div>
