import { fetchBase, downloadBlob } from '.'

export const reportDisabled = [false, false, false, false, false, true]

export const mainCodes = [
  'crisis',
  'ranking',
  'basic',
  'strategic',
  'leadership',
  'systemic',
]
export const subCodes = [
  'crisis',
  'ranking',
  'basic',
  ['swot', 'bsc', 'canvas'],
  ['cultura', 'competencia'], // 'cvo'
  'systemic',
  // ['borda', 'matriz', 'arquetipo'],
]
export const mainTitles = [
  'Crise COVID19',
  'Ranking por áreas de gestão',
  'Consistência e paradoxos nas respostas',
  'Pontos fortes/fracos e oportunidades/ameaças, BSC e Canvas',
  'Comportamento das lideranças e cultura empresarial',
  'Análise sistêmica',
]
export const titles = {
  free: 'Notas na sequência das perguntas',
  crisis: mainTitles[0],
  ranking: mainTitles[1],
  basic: mainTitles[2],
  strategic: mainTitles[3],
  leadership: mainTitles[4],
  systemic: mainTitles[5],
  swot: 'SWOT (pontos fortes e fracos, oportunidades e ameaças)',
  bsc: 'Indicadores Estratégicos (Balanced Scorecard)',
  canvas: 'Modelo de Negócios (Canvas)',
  cultura: 'Cultura Organizacional',
  competencia: 'Competências de Liderança',
  cvo: 'Ciclo de Vida da Organização',
  borda: 'Mapa de interdependência',
  matriz: 'Matriz de Causalidade',
  arquetipo: 'Dinâmica crítica da organização',
}
export const costs = {
  free: 0,
  crisis: 0,
  ranking: 0,
  basic: 100,
  strategic: 300,
  leadership: 300,
  systemic: 100,
  swot: 100,
  bsc: 100,
  canvas: 100,
  cultura: 100,
  competencia: 100,
  cvo: 100,
  borda: 100,
  matriz: 100,
  arquetipo: 100,
}

export async function generateReport(diag, report) {
  const token = await firebase.auth().currentUser.getIdToken()

  return fetchBase(
    'https://us-central1-mapee-b24d4.cloudfunctions.net/reports',
    {
      method: 'POST',
      token,
      responseType: 'blob',
      data: {
        p: diag.ref.parent.parent.id,
        d: diag.id,
        type: report,
      },
    },
  ).then(([status, response]) => {
    if (status === 200) {
      return downloadBlob(
        response,
        `report-${report}.${response.type.includes('pdf') ? 'pdf' : 'html'}`,
      )
    } else {
      return false
    }
  })
}

export async function generatePayment(diag, reports) {
  const token = await firebase.auth().currentUser.getIdToken()
  return fetchBase(
    'https://us-central1-mapee-b24d4.cloudfunctions.net/payments',
    {
      method: 'POST',
      token,
      data: {
        p: diag.ref.parent.parent.id,
        d: diag.id,
        types: reports,
        redirect: location.origin,
      },
    },
  ).then(([status, response]) => {
    if (status === 200) {
      if (response.status === 'success' && !response.data) return true
      if (response.status === 'success' && response.data.status === 'APPROVED')
        return true

      location.href = response.data.links.find((l) => l.rel === 'approve').href
      return false
    }
  })
}

/**
 *
 * @param {Object} data
 * @param {Array} types
 */
export function checkToProcess(data, types) {
  // Se tem algo a pagar
  return !!(
    (Array.isArray(types) && // A ordem de serviço não combina com o que foi pago
      !types.reduce((a, v) => data.reportPermission.includes(v) && a, true)) ||
    // O pagamento está expirado
    (data.orderExpires && data.orderExpires.toDate().getTime() < Date.now())
  )
}

/**
 *
 * @param diag
 * @param {Array} types
 */
export async function callReportsProcess(diag, types) {
  let diagPayment = await firebase
    .firestore()
    .doc(diag.ref.path.replace('diagnostics', 'diagnostics-meta'))
    .get()

  let toProcess = checkToProcess(diagPayment.data(), types)

  if (toProcess) {
    // Processar pagamento
    return generatePayment(diag, types).then((res) => {
      if (res) {
        history.pushRoute('/report_payment_success', {
          p: diag.ref.parent.parent.id,
          d: diag.id,
          types: types,
        })
      }
    })
  } else {
    history.pushRoute('/report_payment_success', {
      p: diag.ref.parent.parent.id,
      d: diag.id,
      types: types,
    })
  }
}
