export function scoreIndex(score) {
  switch (true) {
    case score >= 1 && score < 2:
      return 0
    case score >= 2 && score < 3:
      return 1
    case score >= 3 && score < 4:
      return 2
    case score >= 4 && score <= 5:
      return 3
    default:
      return -1
  }
}
