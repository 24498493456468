export default function routeLink(node, params) {
  function onClick(ev) {
    if (!params || !params.disabled)
      history.pushRouteLink(node.getAttribute('href'))
    ev.preventDefault()
    return false
  }

  if (params && params.pure && !params.disabled) {
    node.removeEventListener('click', onClick)
  } else {
    node.addEventListener('click', onClick)
  }

  return {
    update(params) {
      if (params && params.pure && !params.disabled) {
        node.removeEventListener('click', onClick)
      } else {
        node.addEventListener('click', onClick)
      }
    },
    destroy() {
      node.removeEventListener('click', onClick)
    },
  }
}
