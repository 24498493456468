<script>
  import { createEventDispatcher } from 'svelte'
  import tippy from 'tippy.js'
  import 'tippy.js/dist/tippy.css'

  export let list = []

  const dispatch = createEventDispatcher()
  let loaded = false
  let popperObj

  function bindPopper(node) {
    let anchor
    setTimeout(() => {
      anchor = node.previousElementSibling

      popperObj = tippy(anchor, {
        content: node,
        arrow: false,
        interactive: true,
        interactiveDebounce: 300,
        interactiveBorder: 20,
        placement: 'bottom-end',
        hideOnClick: true,
        role: 'menu',
        trigger: 'click',
        theme: 'tippy-custom',
        offset: [0, 2],
        zIndex: 101,
      })
      loaded = true
    }, 100)

    return {
      destroy() {
        if (popperObj) popperObj.destroy()
      },
    }
  }

  function dispatchEventClick(ev, action) {
    if (popperObj) {
      popperObj.hide()
    }
    dispatch('menu', action || ev.detail)
    ev.preventDefault()
    ev.stopPropagation()
    return false
  }
</script>

<style>
  menu {
    display: none;
    flex-direction: column;
    padding: 0px;
    background-color: var(--surface, #fff);
    color: var(--on-surface, #121212);
    box-sizing: border-box;
    border: 1px solid var(--on-surface-hint, #121212);
  }

  li {
    list-style: none;
  }

  i {
    margin-right: 1rem;
  }

  menu.loaded {
    display: flex;
  }

  button {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    outline: none;
    border: none;
    background-color: transparent;
    color: currentColor;
    box-sizing: border-box;
  }
  button:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }

  :global(.tippy-box[data-theme~='tippy-custom']),
  :global(.tippy-box[data-theme~='tippy-custom'] .tippy-content) {
    padding: 0;
    margin: 0;
    background-color: transparent;
  }
</style>

<menu use:bindPopper class:loaded>
  {#each list as item (item.action)}
    {#if Array.isArray(item.list)}
      <li>
        <span>
          {#if item.icon}
            <i class="material-icons">{item.icon}</i>
          {/if}
          {item.label}
        </span>
        <svelte:self list={item} on:menu={ev => dispatchEventClick(ev)} />
      </li>
    {:else}
      <li>
        <button on:click={ev => dispatchEventClick(ev, item.action)}>
          {#if item.icon}
            <i class="material-icons">{item.icon}</i>
          {/if}
          {item.label}
        </button>
      </li>
    {/if}
  {/each}
</menu>
