<script>
  // Components
  import FlButton from 'fl-components/single/fl-button.svelte'
  import TutorialBox from 'mapee-components/single/tutorial-box.svelte'
  import AlertBox from 'mapee-components/single/alert-box.svelte'

  // Stores
  import { urlData } from 'mapee-stories/router'
  import { questions } from 'mapee-stories/live-data'

  export let doc

  let computedQuestions = []
  // let isCrisis = false

  $: if (doc && $urlData.query.g) {
    let docData = doc.data()
    // isCrisis = docData.profitability

    if ($urlData.query.filter) {
      //Fix Modification
      if (!docData.questions[0].filter) {
        let newQuestions = docData.questions.map(q => {
          let findQ = $questions.find($q => $q.id === q.name)
          return Object.assign(q, { filter: findQ.data().filter })
        })

        doc.ref.set({ questions: newQuestions }, { merge: true })
      } else {
        computedQuestions = docData.questions.filter(q => {
          return q.filter.includes($urlData.query.filter)
        })
        if (computedQuestions.findIndex(q => q.name === $urlData.query.q) < 0) {
          callQuestion(computedQuestions[0])
        }
      }
    } else {
      computedQuestions = docData.questions.filter(
        q => q.group === $urlData.query.g
      )
    }
  }

  function callQuestion(q) {
    history.pushRoute(
      '/diag',
      Object.assign($urlData.query, {
        g: q.group,
        q: q.name,
      })
    )
  }

  function getIcon(name, qs) {
    let q = qs.find(e => e.id === name)
    if (q && q.data().icon) return q.data().icon
    else return ''
  }

  function toggleFilter(filter) {
    let cQuery = Object.assign({}, $urlData.query)

    if ($urlData.query.filter) {
      delete cQuery.filter
    } else {
      cQuery.filter = filter
    }

    history.replaceRoute('/diag', cQuery)
  }
</script>

<style>
  .group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
  }

  .group .items {
    --custom-fl-button-bg-color: var(--surface, #fff);
    --custom-fl-button-color: var(--on-surface, var(--color-black));
  }

  .group .items > :global(*) {
    width: 100%;
  }

  i {
    position: relative;
    left: -0.5rem;
  }

  .group .items.selected {
    --custom-fl-button-bg-color: var(--primary);
    --custom-fl-button-color: var(--on-primary);
  }
  /* .finished {
    --custom-fl-button-bg-color: white;
    --custom-fl-button-color: black;
  }
  .jumped {
    --custom-fl-button-bg-color: white;
    --custom-fl-button-color: black;
  }
  .inapplicable {
    --custom-fl-button-bg-color: white;
    --custom-fl-button-color: black;
  } */

  i:empty {
    display: none;
  }

  .group :global(.results-button) {
    margin: 2rem 1rem;
  }

  .group :global(.col-span) {
    grid-column: span 3;
  }
</style>

<div class="group">
  {#each computedQuestions as q, i (q.name)}
    <div
      class:selected={$urlData.query.q === q.name}
      class:finished={q.status === 'finished'}
      class:jumped={q.status === 'jumped'}
      class:inapplicable={q.status === 'inapplicable'}
      class="items">
      <FlButton on:click={() => callQuestion(q)}>
        {#if q.status === 'finished'}
          <i class="material-icons">check</i>
        {:else if q.status === 'inapplicable'}
          <i class="material-icons">close</i>
        {:else if q.status === 'not-important'}
          <i class="material-icons">low_priority</i>
        {/if}

        <!-- <i slot="left" class="material-icons">
          {getIcon(q.name, $questions)}
        </i> -->
        {q.name}
      </FlButton>
    </div>
    {#if i === 0}
      <TutorialBox emphasis step="6" />
    {/if}
  {/each}
  <!-- {#if !isCrisis} -->
  <FlButton
    href="/actionmap"
    type="link"
    className="col-span results-button"
    isAccent>
    Ver resultados
  </FlButton>
  <TutorialBox emphasis step="7" />
  <!-- {:else}
    <a href="/crisis" use:routeLink class="type-body">Ver resultado</a>
  {/if} -->
  <AlertBox className="col-span">
    {#if $urlData.query.filter !== 'crisis'}
      <p>
        Responda um questionário especial preparado para este momento difícil.
      </p>
    {:else}
      <p>Responda o questionário completo.</p>
    {/if}
    <FlButton
      outline={$urlData.query.filter !== 'crisis'}
      on:click={() => toggleFilter('crisis')}>
      {#if $urlData.query.filter !== 'crisis'}
        Vencendo a crise
      {:else}Todas as questões{/if}
    </FlButton>
  </AlertBox>
</div>
