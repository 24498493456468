<script>
  export let state = 'normal'
  export let className = ''

  let color = 'blue'
  $: switch (state) {
    case 'success':
      color = 'green'
      break
    case 'warning':
    case 'danger':
    case 'error':
      color = 'orange'
      break
    case 'success':
      color = 'green'
      break
    case 'normal':
    default:
      color = 'blue'
  }
</script>

<style>
  .alert-box {
    --ab-bg: black;
    --ab-color: white;
    --custom-fl-button-bg-color: var(--ab-color);
    --custom-fl-button-color: var(--ab-bg);
    background-color: var(--ab-bg);
    color: var(--ab-color);
    display: grid;
    grid-template-columns: 1fr;
    justify-items: stretch;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0.25rem;
    text-align: center;
  }
</style>

<div
  class="alert-box {className}"
  style="--ab-bg: var(--mapee-{color}-50);--ab-color:var(--mapee-{color}-900)">
  <slot />
</div>
