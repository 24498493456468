<script>
  export let icon = ''
  export let ariaLabel = ''
  export let node = null
  export let isAccent = false
</script>

<style>
  button {
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    background-color: var(--accent);
    color: var(--on-accent);
    border: none;
    user-select: none;
    padding: 0px;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100000000px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 800;
  }

  button.is-primary {
    background-color: var(--primary, #121212);
  }

  button:hover,
  button:focus {
    background-color: var(--dark-accent, #222);
  }

  button.is-primary:hover,
  button.is-primary:focus {
    background-color: var(--dark-primary, #222);
  }

  i {
    padding: 0px;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    color: inherit;
  }
</style>

<button
  type="button"
  class:is-primary={isAccent}
  aria-label={ariaLabel}
  on:click
  bind:this={node}>
  <slot>
    <i class="material-icons" aria-hidden="true">{icon}</i>
  </slot>
</button>
