<script>
  import { beforeUpdate } from 'svelte'
  // Stores
  import { projectDoc } from 'mapee-stories/live-data'
  import { urlData } from 'mapee-stories/router'

  // Components
  import TutorialBox from 'mapee-components/single/tutorial-box.svelte'

  // Scripts
  import { TimerTimeout } from 'mapee-helpers/timer'

  const firestore = firebase.firestore()

  let value = ''
  let valueToSave = ''
  let loading = false
  let diagDoc
  let diagListener
  let saveData
  let node

  $: if ($projectDoc && !loading) {
    loading = true
    if (diagListener) diagListener()
    diagListener = $projectDoc.ref
      .collection('diagnostics')
      .doc($urlData.query.d)
      .onSnapshot($doc => {
        diagDoc = $doc
        let data = $doc.data()
        // if (data.profitability) {
        //   value = 'Vencendo a crise'
        // } else {
        //   }
        value = data ? data.title : ''
      })

    saveData = new TimerTimeout(() => {
      diagListener = diagDoc.ref.set(
        {
          title: valueToSave,
          modified: new Date(),
        },
        { merge: true }
      )
    })
  }

  function blockEnter(ev) {
    if (ev.keyCode === 13) {
      ev.preventDefault()
      ev.stopPropagation()
      return false
    }
  }

  function plainTextPasteReplaceBreakLine(ev) {
    ev = ev.originalEvent || ev
    let text = ev.clipboardData.getData('text/plain')
    text = text.replace(/\s/g, ' ')
    document.execCommand('insertHTML', false, text)

    ev.preventDefault()
    ev.stopPropagation()
    return false
  }

  function onInputTitle(ev) {
    valueToSave = ev.target.innerText
    value = valueToSave
    saveData.start(1000)
  }

  beforeUpdate(() => {
    if (node && node.innerText !== value) {
      node.innerText = value
    }
  })
</script>

<style>
  .input {
    display: inline-block;
    box-sizing: border-box;
    min-width: 6rem;
    border: 1px dashed rgba(255, 255, 255, 0);
    background-color: transparent;
    color: inherit;
  }

  .input:empty {
    border-bottom: 1px dashed rgba(255, 255, 255, 1);
    animation: pulse-border 2s ease-in-out infinite alternate both;
  }
  .input:empty::after {
    content: attr(placeholder);
    color: rgba(255, 255, 255, 0.6);
  }

  @keyframes pulse-border {
    from {
      border-bottom-color: rgba(255, 255, 255, 6);
    }
    to {
      border-bottom-color: rgba(255, 255, 255, 0.36);
    }
  }
</style>

<!-- disabled={diagDoc && diagDoc.exists} -->
<span
  class="type-h6 input"
  rule="h1"
  spellcheck="true"
  placeholder="Clique para adicionar um nome"
  contenteditable=""
  bind:this={node}
  on:keydown={blockEnter}
  on:paste={plainTextPasteReplaceBreakLine}
  on:input={onInputTitle} />
<TutorialBox emphasis step="1" />
