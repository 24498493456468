<script>
  import { createPopper } from '@popperjs/core'

  export let text = ''

  $: if (text && popperObj) popperObj.update()

  let focusNode
  let anchor
  let popperObj

  function tutorialPopperDirective(node) {
    anchor = node.previousElementSibling
    popperObj = createPopper(anchor, node, {
      strategy: 'fixed',
      placement: 'bottom',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom', 'top', 'right', 'left'],
          },
        },
        {
          name: 'offset',
          options: {
            offset: [4, 4],
          },
        },
        {
          name: 'listenerUpdate',
          enabled: true,
          phase: 'afterWrite',
          fn() {
            if (!focusNode) return

            boundBox = anchor.getBoundingClientRect()
            focusNode.style.width = `calc(${boundBox.width}px + 4px)`
            focusNode.style.height = `calc(${boundBox.height}px + 4px)`
            focusNode.style.top = `calc(${boundBox.top}px - 2px`
            focusNode.style.left = `calc(${boundBox.left}px - 2px)`
          },
        },
      ],
    })

    setTimeout(() => {
      popperObj.update()
      if (!focusNode) return
    }, 300)
    return {
      update() {
        popperObj.update()
      },
      destroy() {
        popperObj.destroy()
      },
    }
  }
</script>

<div class="tootip type-caption" use:tutorialPopperDirective>{text}</div>

<style>
  :global(*:hover) + .tootip {
    opacity: 1;
  }

  .tootip {
    padding: 0.25rem 0.5rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.52);
    border-radius: 0.25rem;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    pointer-events: none;
  }
</style>
