<script>
  // Components
  import DrawerContent from './drawer-components/drawer-content.svelte'
  import AppBar from './app-bar.svelte'
  import RouteContent from './route-content.svelte'
  import Scrim from './scrim.svelte'

  // Stores
  import { config, screen } from 'mapee-stories/main'
</script>

<style>
  .app-layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: stretch;
  }

  .drawer {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 16rem;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: var(--surface, white);
    transition: transform 0.4s ease;
    overflow-y: auto;
  }

  .drawer.closed {
    transform: translate3d(-100%, 0, 0);
  }

  @media screen and (min-width: 1024px) {
    .app-layout {
      display: grid;
      grid-template-columns: 16rem 1fr;
      grid-template-rows: 3.5rem 1fr;
      grid-template-areas:
        'drawer appbar'
        'drawer content';
      min-height: 100%;
    }

    .app-layout.no-drawer {
      grid-template-columns: 1fr;
      grid-template-areas:
        'appbar'
        'content';
    }

    .drawer {
      grid-area: drawer;
    }
    .drawer.closed {
      transform: none;
    }
  }
</style>

<div class="app-layout" class:no-drawer={$config.noDrawer}>
  {#if !$config.noDrawer}
    <div class="drawer" class:closed={!$screen.drawerOpen}>
      <DrawerContent />
    </div>
  {/if}
  <Scrim />
  <AppBar />
  <RouteContent />
</div>
