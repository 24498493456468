<script>
  import formSerializer from 'mapee-scripts/form-serializer'
  import { fetchBase } from 'mapee-helpers'
  // Stores
  import { user } from 'mapee-stories/main'
  // Componentes
  import FLButton from 'fl-components/single/fl-button.svelte'

  export const config = {
    title: 'Feedback',
  }

  function onSubmit(ev) {
    let data = formSerializer(ev.target)
    let url = ev.target.getAttribute('action')

    fetchBase(url, { method: 'POST', data })
      .then(([status, response]) => {
        if (status === 200 && response.status === 'success') {
          history.pushRoute('/feedback-success')
        }
      })
      .catch(err => {
        console.error(err)
        error = true
      })

    ev.preventDefault()
    return false
  }

  let error = false
</script>

<style>
  .title {
    margin: 1.5rem 0px;
    padding: 0px 1rem;
  }

  .profile-info {
    background-color: var(--surface);
    color: var(--on-surface);
  }

  .profile-info header {
    padding: 1rem;
  }

  label {
    margin: 0 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
  }
  input {
    margin-bottom: 0;
  }

  footer {
    padding: 1rem;
  }
</style>

<section>
  <header>
    <h2 class="title type-h5">Formulário de contato</h2>
  </header>
  <div class="profile-info">
    <header>
      <p class="type-body">
        Obrigado por dedicar seu tempo para falar conosco, seu feedback é muito
        importante para manter uma melhora crescente. Sinta-se livre para enviar
        elogios, críticas e/ou sugestões:
      </p>
    </header>
    {#if error}
      <p class="type-body error">Não foi possível enviar seu comentário!</p>
    {/if}
    <form
      on:submit={onSubmit}
      method="POST"
      action="https://us-central1-fluidocms.cloudfunctions.net/formContactSendEmail">
      <input
        type="hidden"
        name="subject"
        value="Feedback do Aplicativo MAPEE" />
      <input type="hidden" name="mailTo" value="contato@mapee.com.br" />
      <input type="hidden" name="name" value={$user.displayName} />
      <input type="hidden" name="email" value={$user.email} />
      <label class="type-caption">
        Sua mensagem
        <textarea class="type-body" name="message" />
      </label>
      <footer>
        <FLButton type="submit" text>Enviar feedback</FLButton>
      </footer>
    </form>
  </div>
</section>
