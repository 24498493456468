<script>
  import { scoreIndex } from 'mapee-helpers/crisis-helper'

  export let focus = false
  export let semaphore = 0

  $: index = scoreIndex(semaphore)
</script>

<style>
  .score-chip {
    --score-chip-color: var(--on-primary, #fff);
    --score-chip-bg-color: var(--on-surface-hint, gray);
    --score-chip-focus-color: var(--score-chip-bg-color, gray);
    --avatar-color: var(--score-chip-color, #fff);
    --avatar-bg-color: var(--on-surface-secondary, #121212);
    background-color: var(--score-chip-bg-color);
    color: var(--score-chip-color, #fff);
    display: inline-flex;
    border-radius: 9999px;
    padding: 0.25rem;
  }

  span {
    line-height: 1.5rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .score-chip.focus {
    box-shadow: 0px 0px 0px 2px var(--surface),
      0px 0px 0px 4px var(--score-chip-focus-color, inherit);
  }

  .score-chip.score-0 {
    --score-chip-bg-color: var(--mapee-orange-500);
    --avatar-bg-color: var(--mapee-orange-700);
  }

  .score-chip.score-0.focus {
    --avatar-bg-color: var(--mapee-orange-800);
  }

  .score-chip.score-1 {
    --score-chip-color: var(--on-surface);
    --score-chip-bg-color: var(--mapee-yellow-600);
    --avatar-bg-color: var(--mapee-yellow-800);
  }

  .score-chip.score-1.focus {
    --avatar-bg-color: var(--mapee-yellow-900);
  }
  .score-chip.score-2 {
    --score-chip-bg-color: var(--mapee-green-600);
    --avatar-bg-color: var(--mapee-green-800);
  }

  .score-chip.score-2.focus {
    --avatar-bg-color: var(--mapee-green-900);
  }

  .score-chip.score-3 {
    --score-chip-bg-color: var(--mapee-blue-500);
    --avatar-bg-color: var(--mapee-blue-700);
  }

  .score-chip.score-3.focus {
    --avatar-bg-color: var(--mapee-blue-800);
  }
</style>

<div class="score-chip score-{index}" class:focus>
  <slot name="avatar" />
  <span>
    <slot />
  </span>
  <slot name="icon" />
</div>
