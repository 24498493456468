<script>
  export const config = {
    title: 'Pagina não encontrada',
  }
</script>

<style>
  .title {
    margin: 1.5rem 0px;
    padding: 0px 1.5rem;
  }
</style>

<section>
  <h3 class="title">Erro 404</h3>
</section>
