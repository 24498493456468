<script>
  import { onMount, onDestroy } from 'svelte'
  import moment from 'moment'

  // Stores
  import { urlData } from 'mapee-stories/router'
  import {
    descriptions,
    projectDoc,
    questions,
    crisisQuestions,
  } from 'mapee-stories/live-data'
  import { tutoStep } from 'mapee-stories/tutorial'

  // Helpers
  import {
    checkToProcess,
    generatePayment,
    generateReport,
  } from 'mapee-helpers/report-helper'

  // Components
  import CrisisItems from 'mapee-components/complex/crisis-components/crisis-items.svelte'
  import CrisisItemDetails from 'mapee-components/complex/crisis-components/crisis-item-details.svelte'
  import ScoreChip from 'mapee-components/complex/crisis-components/score-chip.svelte'
  import Modal from 'fl-components/simple/fl-modal.svelte'
  import FLIndeterminateLoading from 'fl-components/single/fl-indeterminate-loading.svelte'
  import FLButton from 'fl-components/single/fl-button.svelte'

  export const config = {
    title: 'Vencendo a crise',
  }

  const firestore = firebase.firestore()
  let loading = true
  let myDiagnostic = null
  let listenerDiagnostic
  let restartDiagModal = false
  let diagToRestart
  let removeDiagModal = false
  let diagToRemove
  let modified = new Date(0)

  let reportMode = false

  let questionsList = []
  let averageScore = 0
  let currentItem = {}

  let waitReportModal = false
  let errorReportModal = false

  $: formattedModified = moment(modified).format('DD/MM/YYYY')

  $: if ($projectDoc && $crisisQuestions.length > 0 && loading) {
    loadDiagnostics()
  }

  async function loadDiagnostics() {
    if (listenerDiagnostic) listenerDiagnostic()
    listenerDiagnostic = $projectDoc.ref
      .collection('diagnostics')
      .where('profitability', '==', true)
      .onSnapshot(async $snap => {
        myDiagnostic = $snap.docs[0]
        if (myDiagnostic) {
          checkCrisisQuestions(myDiagnostic)
          loading = false
          questionsList = myDiagnostic.data().questions.filter(q => q.crisis)
          reportMode = questionsList.filter(q => q.status !== 'none').length > 0
          let averageList = questionsList.filter(q => q.status === 'finished')
          averageScore = averageList.reduce(
            (a, v) => a + Math.cbrt(v.gravity * v.urgency * v.future),
            0
          )
          averageScore /= averageList.length || 1

          modified = myDiagnostic.data().modified.toDate()
        } else {
          let clearQuestions = $questions.map(q => ({
            order: q.data().order,
            globalOrder: q.data().globalOrder,
            name: q.data().name,
            group: q.data().group,
            crisis: q.data().crisis || false,
            crisisOrder: q.data().crisisOrder || 0,
            status: 'none',
            gravity: 0,
            urgency: 0,
            future: 0,
          }))
          $projectDoc.ref.collection('diagnostics').add({
            title: 'Vencendo a crise',
            modified: new Date(),
            profitability: true,
            questions: clearQuestions,
          })
        }
      })
  }

  async function checkCrisisQuestions(diag) {
    let diagData = diag.data()
    let questions = diagData.questions
    let save = false

    questions.forEach(q => {
      let data = $crisisQuestions.find(e => e.data().name === q.name)
      if (data) data = data.data()

      if (
        data &&
        (q.crisis !== data.crisis || q.crisisOrder !== data.crisisOrder)
      ) {
        save = true
        q.crisis = data.crisis
        q.crisisOrder = data.crisisOrder
        if (!q.crisis) {
          q.status = 'none'
          q.gravity = 0
          q.urgency = 0
          q.future = 0
        }
      } else if (!data && q.crisis) {
        save = true
        q.crisis = false
        q.crisisOrder = 0
        q.status = 'none'
        q.gravity = 0
        q.urgency = 0
        q.future = 0
      }
    })
    if (save) {
      return diag.ref.set({ questions }, { merge: true })
    } else return true
  }

  async function printCrisisReport() {
    waitReportModal = true

    let diagMeta = await firebase
      .firestore()
      .doc(myDiagnostic.ref.path.replace('diagnostics', 'diagnostics-meta'))
      .get()

    // let toProcess = checkToProcess(diagMeta.data(), ['crisis'])

    await generatePayment(diagMeta, ['crisis'])
    await generateReport(myDiagnostic, 'crisis')
    // if (toProcess) {
    // } else {
    //   throw new Error('Permission error')
    // }
  }

  onDestroy(() => {
    if (listenerDiagnostic) listenerDiagnostic()
  })
</script>

<style>
  .crisis-report {
    display: grid;
    grid-gap: 1rem;
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
    height: 100%;
  }

  @media screen and (min-width: 688px) {
    .crisis-report {
      grid-template-columns: minmax(20rem, 24rem) minmax(20rem, 35rem);
      grid-template-rows: auto 1fr;
      padding: 1rem;
      box-sizing: border-box;
      max-height: 100%;
      overflow: hidden;
    }
  }

  header {
    grid-column: 1/-1;
    background-color: var(--surface);
    padding: 2.5rem 2rem;
    box-sizing: border-box;
    border-radius: 0.25rem;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  nav {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  article {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  .report-title .modified {
    opacity: 0.6;
    margin-bottom: 1.6rem;
  }
</style>

<Modal bind:open={errorReportModal} title="Erro">
  <p>Algo inesperado aconteceu.</p>
  <p>
    Tente novamente mais tarde. Caso o erro persista entre em contato com o
    suporte
  </p>
</Modal>

<Modal
  locked
  bind:open={waitReportModal}
  title="Aguarde enquanto processamos...">
  <div class="box-center">
    <FLIndeterminateLoading />
  </div>
</Modal>

<section>
  <div class="crisis-report">

    <header>
      <div class="report-title">
        <h1 class="type-h4">Relatório para enfrentar a crise da COVID-19</h1>
        <p class="type-subtitle-1 modified">
          Atualizado em
          <time datetime={formattedModified}>{formattedModified}</time>
        </p>
        <p class="type-body-1">
          A covid-19 surgiu provocando mudanças conjunturais e estruturais na
          vida econômica. Praticamente todas as atividade e empresas foram
          impactadas pelo coronavírus. O modo como ela atinge cada organização
          depende de fatores externos e também de aspectos internos das
          empresas. Esta Resposta que o Mapee proporciona traz indicações sobre
          o quanto o seu negócio está preparado para enfrentar e sobreviver à
          crise.
        </p>
      </div>
      <ScoreChip semaphore={averageScore}>{averageScore.toFixed(1)}</ScoreChip>
    </header>
    {#if myDiagnostic}
      <nav>
        <CrisisItems
          diagId={myDiagnostic.id}
          bind:currentItem
          bind:list={questionsList}
          on:callPrint={() => printCrisisReport()
              .catch(err => {
                console.error(err)
                errorReportModal = true
              })
              .finally(() => {
                waitReportModal = false
              })} />
      </nav>

      <article>
        <CrisisItemDetails
          diagId={myDiagnostic.id}
          item={currentItem}
          questions={$crisisQuestions} />
      </article>
    {/if}
  </div>
</section>
