<script>
  // Stores
  import { user } from 'mapee-stories/main'
  import { userData, userMeta } from 'mapee-stories/live-data'

  // Componentes
  import FLButton from 'fl-components/single/fl-button.svelte'
  import FLModal from 'fl-components/simple/fl-modal.svelte'

  export const config = {
    title: 'Perfil',
  }

  let sendRequest = false
  let academicStudentForm = {}
  let academicProfessorForm = {}

  let isStudent = false
  let isProfessor = false

  $: if ($userMeta.exists) {
    let d = $userMeta.data()
    isStudent = !!(d.academic && !d.professor)
    isProfessor = !!d.professor
  }

  function onSubmit(type) {
    switch (type) {
      case 'student':
        $userData.ref.set(
          Object.assign(
            {
              waitAcademicRequest: true,
              type,
            },
            academicStudentForm
          ),
          { merge: true }
        )
        break
      case 'professor':
        $userData.ref.set(
          Object.assign(
            {
              waitAcademicRequest: true,
              type,
            },
            academicProfessorForm
          ),
          { merge: true }
        )
        break
    }
    sendRequest = true
  }
</script>

<style>
  .title {
    margin: 1.5rem 0px;
    padding: 0px 1rem;
  }

  .profile-info {
    background-color: var(--surface);
    color: var(--on-surface);
    padding-top: 0.5rem;
  }

  h4 {
    padding: 1rem;
  }

  fieldset {
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid var(--border);
  }

  legend {
    padding: 0 0.5rem;
  }

  label {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
    max-width: 16em;
  }
  input {
    margin-bottom: 0;
  }

  footer {
    display: flex;
    flex-direction: row-reverse;
    padding: 1rem;
  }
</style>

<FLModal bind:open={sendRequest} title="Requisição enviada">
  Aguarde a resposta da nossa equipe.
</FLModal>

<section>
  <header>
    <h2 class="title type-h5">Suas informações</h2>
  </header>

  <div class="profile-info">
    {#if isStudent}
      <h4>Você ja tem uma conta estudante</h4>
    {:else}
      <form on:submit|preventDefault={() => onSubmit('student')}>
        <fieldset>
          <legend class="type-subtitle-1">
            Dados acadêmicos para estudante
          </legend>
          <label class="type-caption">
            Instituição de ensino
            <input
              class="type-body"
              type="text"
              required
              bind:value={academicStudentForm.academy} />
          </label>
          <label class="type-caption">
            Curso / Graduação
            <input
              class="type-body"
              type="text"
              required
              bind:value={academicStudentForm.graduation} />
          </label>
        </fieldset>
        <footer>
          <FLButton type="submit" text>requisitar conta estudante</FLButton>
        </footer>
      </form>
    {/if}
  </div>
  <div class="profile-info">
    {#if isProfessor}
      <h4>Você ja tem uma conta de professor</h4>
    {:else}
      <form on:submit|preventDefault={() => onSubmit('professor')}>
        <fieldset>
          <legend class="type-subtitle-1">
            Dados acadêmicos para professor
          </legend>
          <label class="type-caption">
            Currículo lattes
            <input
              class="type-body"
              type="url"
              required
              bind:value={academicProfessorForm.lattes} />
          </label>
          <label class="type-caption">
            Instituição de ensino
            <input
              class="type-body"
              type="text"
              required
              bind:value={academicProfessorForm.academy} />
          </label>
          <label class="type-caption">
            Disciplina que leciona
            <input
              class="type-body"
              type="text"
              required
              bind:value={academicProfessorForm.discipline} />
          </label>
        </fieldset>
        <footer>
          <FLButton type="submit" text>requisitar conta professor</FLButton>
        </footer>
      </form>
    {/if}
  </div>
</section>
