<script>
  import { onMount, onDestroy } from 'svelte'

  // Helpers
  import {
    gravityChartLabels,
    urgencyChartLabels,
    futureChartLabels,
  } from 'mapee-helpers/consts'
  import { createHash } from 'mapee-helpers'
  import {
    callCreateDiagnostic,
    chartDirective,
    computeId,
    computeTitle,
    computeSubtitle,
    computeProgress,
    computeScore,
  } from 'mapee-helpers/diagnostic-helper'

  // Components
  import QuestionGroupItem from 'mapee-components/single/question-group-item.svelte'
  import TutorialBox from 'mapee-components/single/tutorial-box.svelte'
  import Modal from 'fl-components/simple/fl-modal.svelte'
  import Collapse from 'fl-components/simple/fl-collapse-panel.svelte'
  import Menu from 'fl-components/single/fl-menu.svelte'
  import FLButton from 'fl-components/single/fl-button.svelte'
  import FLIconButton from 'fl-components/single/fl-icon-button.svelte'
  import FAB from 'fl-components/single/fl-fab.svelte'

  // Stores
  import { urlData } from 'mapee-stories/router'
  import { descriptions, projectDoc, questions } from 'mapee-stories/live-data'
  import { tutoStep } from 'mapee-stories/tutorial'

  export const config = {
    title: 'Questionário',
  }

  const firestore = firebase.firestore()
  let loading = false
  let myDiagnostics = []
  let listenerDiagnostics
  let restartDiagModal = false
  let diagToRestart
  let removeDiagModal = false
  let diagToRemove

  $: if ($projectDoc && !loading) {
    loadDiagnostics()
  }

  async function loadDiagnostics() {
    if (listenerDiagnostics) listenerDiagnostics()
    listenerDiagnostics = $projectDoc.ref
      .collection('diagnostics')
      // .where('profitability', '==', false)
      .orderBy('modified', 'desc')
      .onSnapshot($snap => {
        myDiagnostics = $snap.docs
        loading = true
      })
  }

  function autoFocusDirective(node) {
    node.focus()
    return function() {}
  }

  function receiveActionsMenu({ detail }, diag) {
    switch (detail) {
      case 'restart':
        diagToRestart = diag
        restartDiagModal = true
        break
      case 'remove':
        diagToRemove = diag
        removeDiagModal = true
        break
    }
  }

  function callRestartDiagnostic() {
    if (diagToRestart) {
      history.pushRouteLink(`/diag?d=${diagToRestart.id}&action=restart`)
    }
    restartDiagModal = false
  }
  function callRemoveDiagnostic() {
    if (diagToRemove) {
      diagToRemove.ref.delete()
    }
    removeDiagModal = false
  }

  function callStartTutorial() {
    tutoStep.set(0)
  }

  onDestroy(() => {
    if (listenerDiagnostics) listenerDiagnostics()
  })
</script>

<style>
  section.page {
    font-size: 1rem;
    padding-bottom: 4rem;
    outline: none;
    opacity: 0;
    transition: opacity 0.3s;
  }

  section.page.load {
    opacity: 1;
  }

  .title {
    margin: 1.5rem 0px;
    padding: 0px 1.5rem;
  }

  .question-groups {
    display: grid;
    grid-template-columns: 1fr;
  }

  .charts-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 2rem;
    overflow: auto;
  }

  .charts-group canvas {
    margin: 2rem 0;
  }

  .buttons-group > :global(*:first-child) {
    margin-right: auto;
    margin-left: 1rem;
  }
</style>

<Modal open={restartDiagModal} title="Zerar dados do diagnostico">
  <p class="type-body">
    Deseja realmente reiniciar o diagnóstico {diagToRestart ? diagToRestart.data().title : '*'}?
  </p>
  <div slot="actions">
    <FLButton
      text
      on:click={() => {
        restartDiagModal = false
        diagToRestart = null
      }}>
      cancelar
    </FLButton>
    <FLButton text on:click={callRestartDiagnostic}>reiniciar</FLButton>
  </div>
</Modal>
<Modal open={removeDiagModal} title="Excluir diagnostico">
  <p class="type-body">
    Deseja realmente excluir o diagnóstico {diagToRemove ? diagToRemove.data().title : '*'}?
  </p>
  <div slot="actions">
    <FLButton
      text
      on:click={() => {
        removeDiagModal = false
        diagToRemove = null
      }}>
      cancelar
    </FLButton>
    <FLButton text on:click={callRemoveDiagnostic}>excluir</FLButton>
  </div>
</Modal>

<FAB
  icon="add"
  on:click={() => callCreateDiagnostic($projectDoc, $questions)} />
<TutorialBox emphasis propagate step="0" />

<section class="page" class:load={loading} tabindex="-1" use:autoFocusDirective>
  <h2 class="title type-h5">Respostas</h2>
  <Collapse
    let:item
    list={myDiagnostics}
    {computeId}
    {computeTitle}
    {computeSubtitle}>
    <div class="question-groups">
      {#each $descriptions as group, i (group.id)}
        <QuestionGroupItem
          link={`/diag?d=${item.id}&g=${group.id}`}
          autoFocus={i === 0}
          id={group.id}
          title={group.data().type}
          description={group.data().description}
          progress={computeProgress(group, item)}
          score={computeScore(group, item)} />
      {/each}
    </div>
    <div class="charts-group">
      <div>
        <canvas
          width="382px"
          height="128px"
          use:chartDirective={{ diag: item, value: 'gravity', labels: gravityChartLabels }} />
      </div>
      <div>
        <canvas
          width="382px"
          height="128px"
          use:chartDirective={{ diag: item, value: 'future', labels: futureChartLabels }} />
      </div>
      <div>
        <canvas
          width="382px"
          height="128px"
          use:chartDirective={{ diag: item, value: 'urgency', labels: urgencyChartLabels }} />
      </div>
    </div>
    <div class="buttons-group" slot="actions">
      <FLButton
        text
        on:click={() => history.pushRouteLink(`/diag?d=${item.id}`)}>
        continuar diagnostico
      </FLButton>
      <FLIconButton icon="more_vert" ariaLabel="mostrar mais opções" />
      <Menu
        on:menu={ev => receiveActionsMenu(ev, item)}
        list={[{ label: 'zerar e reiniciar', action: 'restart' }, { label: 'excluir', action: 'remove' }]} />
    </div>
  </Collapse>
</section>
