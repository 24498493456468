<script>
  import { createEventDispatcher } from 'svelte'
  import DiagStep from './diag-step.svelte'
  import { chartColors, stepperQuestions } from 'mapee-helpers/consts'

  const dispatch = createEventDispatcher()
  const stepperList = []

  export let data

  let renew = ''

  $: if (renew != data.name) {
    renew = data.name
    if (stepperList[0]) {
      stepperList[0].setOpen(0)
    }
  }

  function callAnswerValues(name, value) {
    data[name] = value
    dispatch('click', data)
  }
</script>

<style>

</style>

{#each stepperQuestions as question, index (question.title)}
  <DiagStep
    {index}
    {stepperList}
    title={question.title}
    question={question.question}
    answers={question.answers}
    colors={chartColors}
    beforeValue={index > 0 ? data[stepperQuestions[index - 1].name] : null}
    value={data[question.name]}
    on:click={({ detail }) => callAnswerValues(question.name, detail)} />
{/each}
