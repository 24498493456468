<script>
  import { questions } from 'mapee-stories/live-data'
  export let diag

  let sortFlags = [0, 0, 0, 0, 0]

  function changeSortFlags(index) {
    for (let i = 0; i < sortFlags.length; i++) {
      if (i !== index) sortFlags[i] = 0
    }
    sortFlags[index]++
    if (sortFlags[index] > 1) sortFlags[index] = -1
  }

  $: questionsAnswered = diag
    .data()
    .questions.filter(q => q.status === 'finished')
    .sort((a, b) => {
      let v0 = b.name > a.name ? 1 : b.name < a.name ? -1 : 0
      v0 *= sortFlags[0]
      let v1 = b.gravity - a.gravity
      v1 *= sortFlags[1]
      let v2 = b.urgency - a.urgency
      v2 *= sortFlags[2]
      let v3 = b.future - a.future
      v3 *= sortFlags[3]
      let v4 =
        b.gravity * b.urgency * b.future - a.gravity * a.urgency * a.future
      v4 *= sortFlags[4]

      return v0 + v1 + v2 + v3 + v4
    })

  let toReport = []
</script>

<style>
  table {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  th {
    height: 3rem;
    line-height: 1.5rem;
  }

  th > .material-icons {
    position: absolute;
  }

  th:not(:empty) {
    cursor: pointer;
  }

  th:first-child {
    width: min-content;
    padding-right: 2rem;
  }

  th:nth-child(2),
  th:nth-child(3),
  th:nth-child(4) {
    width: 2rem;
  }
  th:last-child {
    width: 5rem;
  }

  input[type='checkbox'] > * {
    margin: auto;
  }

  tr td {
    padding: 0.25rem 0;
  }
</style>

<table>
  <thead>
    <tr>
      <th on:click={() => changeSortFlags(0)}>
        Pergunta
        {#if sortFlags[0] > 0}
          <i class="material-icons">arrow_drop_up</i>
        {:else if sortFlags[0] < 0}
          <i class="material-icons">arrow_drop_down</i>
        {/if}
      </th>
      <th on:click={() => changeSortFlags(1)}>
        G
        {#if sortFlags[1] > 0}
          <i class="material-icons">arrow_drop_up</i>
        {:else if sortFlags[1] < 0}
          <i class="material-icons">arrow_drop_down</i>
        {/if}
      </th>
      <th on:click={() => changeSortFlags(2)}>
        U
        {#if sortFlags[2] > 0}
          <i class="material-icons">arrow_drop_up</i>
        {:else if sortFlags[2] < 0}
          <i class="material-icons">arrow_drop_down</i>
        {/if}
      </th>
      <th on:click={() => changeSortFlags(3)}>
        F
        {#if sortFlags[3] > 0}
          <i class="material-icons">arrow_drop_up</i>
        {:else if sortFlags[3] < 0}
          <i class="material-icons">arrow_drop_down</i>
        {/if}
      </th>
      <th on:click={() => changeSortFlags(4)}>
        Pontuação
        {#if sortFlags[4] > 0}
          <i class="material-icons">arrow_drop_up</i>
        {:else if sortFlags[4] < 0}
          <i class="material-icons">arrow_drop_down</i>
        {/if}
      </th>
    </tr>
  </thead>
  <tbody>
    {#each questionsAnswered as q (q.name)}
      <tr>
        <td>
          {q.name}
          {$questions[q.globalOrder - 1] ? $questions[q.globalOrder - 1].data().question : ''}
        </td>
        <td>{q.gravity}</td>
        <td>{q.urgency}</td>
        <td>{q.future}</td>
        <td>{Math.cbrt(q.gravity * q.urgency * q.future).toFixed(2)}</td>
      </tr>
    {/each}
  </tbody>
</table>
