<script>
  export const config = {
    title: 'Em desenvolvimento',
  }
</script>

<style>
  .title {
    margin: 1.5rem 0px;
    padding: 0px 1.5rem;
  }

  section {
    /* opacity: 0; */
    transition: opacity 0.3s;
  }

  /* section.load {
    opacity: 1;
  } */
</style>

<section>
  <h3 class="title">Breve...</h3>
</section>
