export default function lottieDirective(node, params) {
  let options = Object.assign(params, {
    container: node,
    renderer: 'svg',
    loop: true,
    autoplay: true,
  })

  let lottieObj = lottie.loadAnimation(options)

  return {
    destroy() {
      lottieObj.destroy()
    },
  }
}
