<script>
  import { loading } from 'mapee-stories/main'
</script>

<style>
  #loading {
    display: block;
    position: fixed;
    background-color: var(--primary, #121212);
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 2000;
    transition: opacity 0.3s ease, visibility 0s ease 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  #loading.loading {
    visibility: visible;
    opacity: 1;
  }
</style>

<div id="loading" class:loading={$loading}>
  <div />
</div>
