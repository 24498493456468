import './config-firebase'
import App from './app.svelte'

console.log(
  '\n\n%cFluido Design',
  `background-color:#24a2b1;
  color: #fff;
  font-size:24px;
  font-family: sans-serif;
  padding: 8px 24px;
  border-radius: 500px;`
)
console.log(
  '%cPor favor %cnão%c execute nada aqui!',
  'color:red; font-size:20px',
  'color:red; font-size:40px; text-transform:uppercase',
  'color:red; font-size:20px'
)
console.log('%cPreze pela sua segurança!', 'color:red; font-size:20px')

// if (
//   'serviceWorker' in navigator &&
//   navigator.onLine &&
//   location.href.indexOf('mapee.app') >= 0
// ) {
//   navigator.serviceWorker
//     .register('/sw.min.js')
//     .then(function() {
//       console.log('Offline cache installed!')
//     })
//     .catch(function(error) {
//       console.log('Offline cache failed:', error)
//     })
// }

window.appTag = 'BY_FLUIDO'
window.FUP = document.getElementById('fl-fup')
const app = new App({
  target: document.getElementById('app'),
})

export default app
