<script>
  import { onMount, onDestroy } from 'svelte'

  // Components
  import DiagTitle from 'mapee-components/complex/diag-contents/diag-title.svelte'
  import DiagHeader from 'mapee-components/complex/diag-contents/diag-header.svelte'
  import DiagCurrentQuestion from 'mapee-components/complex/diag-contents/diag-current-question.svelte'
  import DiagNextQuestions from 'mapee-components/complex/diag-contents/diag-next-questions.svelte'
  import TutorialBox from 'mapee-components/single/tutorial-box.svelte'

  // Stores
  import {
    projectDoc,
    questions,
    crisisQuestions,
  } from 'mapee-stories/live-data'
  import { urlData } from 'mapee-stories/router'

  // let isCrisis = false

  export const config = {
    title: DiagTitle,
    noDrawer: true,
    backButton: true,
    callBackButton() {
      // history.pushRouteLink(isCrisis ? '/crisis' : '/panel')
      history.pushRouteLink('/panel')
    },
  }

  const firestore = firebase.firestore()

  let loading = false
  let diagDoc
  let diagListener

  $: if ($projectDoc && !loading) {
    loadDiagnostic()
  }

  async function loadDiagnostic() {
    const diagId = $urlData.query.d
    let toRestart = $urlData.query.action === 'restart'
    let toGroup = $urlData.query.g || null
    let toQuestion = $urlData.query.q || null
    let diagRef = firestore.doc(
      `projects/${$projectDoc.id}/diagnostics/${diagId}`
    )

    if (toRestart) {
      let clearQuestions = $questions.map(q => ({
        order: q.data().order,
        globalOrder: q.data().globalOrder,
        name: q.data().name,
        group: q.data().group,
        filter: q.data().filter,
        // crisis: q.data().crisis || false,
        // crisisOrder: q.data().crisisOrder || 0,
        status: 'none',
        gravity: 0,
        urgency: 0,
        future: 0,
      }))

      await diagRef.set(
        { questions: clearQuestions, modified: new Date() },
        { merge: true }
      )
    }

    if (diagListener) diagListener()
    diagListener = diagRef.onSnapshot($doc => {
      diagDoc = $doc
      let diagData = $doc.data()
      // isCrisis = diagData.profitability

      if (!loading) {
        loading = true

        if (toRestart) {
          toGroup = '1w'
        }

        if (toGroup) {
          history.replaceRoute('/diag', {
            d: diagId,
            g: toGroup,
            q: $urlData.query.q || toGroup + 1,
          })
        } else if (toQuestion) {
          history.replaceRoute('/diag', {
            d: diagId,
            g: toGroup,
            q: toQuestion,
          })
        } else if (diagData.lastQuestion) {
          history.replaceRoute('/diag', {
            d: diagId,
            g: diagData.lastQuestion.substring(0, 2),
            q: diagData.lastQuestion,
          })
        } else {
          history.replaceRoute('/diag', {
            d: diagId,
            g: $questions[0].data().group,
            q: $questions[0].data().name,
          })
        }
      }
    })
  }

  onDestroy(() => {
    if (diagListener) diagListener()
  })
</script>

<style>
  .diag {
    display: grid;
    grid-template-columns: 1fr 20rem;
    grid-gap: 1rem 3rem;
    grid-template-rows: auto auto;
    grid-template-areas:
      'head head'
      'main next';
    opacity: 0;
    transition: opacity 0.3s;
  }

  .diag.load {
    opacity: 1;
  }
  header {
    grid-area: head;
    padding: 1rem;
  }

  main {
    grid-area: main;
  }

  aside {
    grid-area: next;
    width: 20rem;
  }
</style>

<section class="diag" class:load={loading}>
  <header>
    <DiagHeader />
  </header>
  <TutorialBox emphasis step="2" />
  <main>
    <DiagCurrentQuestion doc={diagDoc} />
  </main>
  <aside>
    <DiagNextQuestions doc={diagDoc} />
  </aside>
</section>
