<script>
  import { createPopper } from '@popperjs/core'

  // Helpers
  import { tutorial } from 'mapee-stories/live-data'
  import { tutoStep, tutoSize } from 'mapee-stories/tutorial'

  // Components
  import FLIconButton from 'fl-components/single/fl-icon-button.svelte'

  const md = new markdownit({ html: true })

  export let step = Infinity
  export let emphasis = false
  export let propagate = false

  let contentNode = ''

  $: if ($tutorial[+step]) {
    let data = $tutorial[+step].data() || {}
    contentNode = md.render(data.content || '')
    if (popperObj) popperObj.update()
  } else {
    contentNode = ''
    if (popperObj) popperObj.update()
  }

  let focusNode
  let anchor
  let popperObj

  function nextTutorial() {
    tutoStep.update(v => {
      if ($tutoStep + 1 < $tutoSize) return v + 1
      else return -1
    })
    if (propagate) anchor.click()
  }

  function tutorialPopperDirective(node) {
    anchor = node.previousElementSibling
    let boundBox = anchor.getBoundingClientRect()
    popperObj = createPopper(anchor, node, {
      strategy: 'fixed',
      placement: 'bottom',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom', 'top', 'right', 'left'],
          },
        },
        {
          name: 'offset',
          options: {
            offset: [16, 16],
          },
        },
        {
          name: 'listenerUpdate',
          enabled: true,
          phase: 'afterWrite',
          fn() {
            if (!focusNode) return

            boundBox = anchor.getBoundingClientRect()
            focusNode.style.width = `calc(${boundBox.width}px + 4px)`
            focusNode.style.height = `calc(${boundBox.height}px + 4px)`
            focusNode.style.top = `calc(${boundBox.top}px - 2px`
            focusNode.style.left = `calc(${boundBox.left}px - 2px)`
          },
        },
      ],
    })

    // window.scrollTo(0, boundBox.top)
    setTimeout(() => {
      popperObj.update()
      if (!focusNode) return
    }, 300)
    return {
      update() {
        popperObj.update()
      },
      destroy() {
        popperObj.destroy()
      },
    }
  }
</script>

<style>
  .tutorial-box {
    padding: 0.5rem 1rem;
    width: 16rem;
    background-color: var(--surface);
    color: var(--on-surface);
    border: 1px solid black;
    z-index: 2000;
  }

  .tutorial-content :global(*) {
    margin-top: 0;
  }

  .tutorial-steps {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .emphasis-scrim {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1998;
  }

  .emphasis-scrim > .focus-node {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    outline: 2px solid var(--accent, #121212);
    cursor: pointer;
    animation: focus-animation 1s ease-in alternate infinite;
  }

  .emphasis-scrim.emphasis > .focus-node {
    box-shadow: 0 0 0 10000000px rgba(0, 0, 0, 0.38);
    animation: focus-animation-with-emphasis 1s ease-in alternate infinite;
  }

  @keyframes focus-animation {
    0% {
      box-shadow: 0 0 0 0 var(--accent, #121212);
    }
    100% {
      box-shadow: 0 0 1rem 0.5rem var(--accent, #121212);
    }
  }

  @keyframes focus-animation-with-emphasis {
    0% {
      box-shadow: 0 0 0 0 var(--accent, #121212),
        0 0 0 10000000px rgba(0, 0, 0, 0.38);
    }
    100% {
      box-shadow: 0 0 1rem 0.5rem var(--accent, #121212),
        0 0 0 10000000px rgba(0, 0, 0, 0.38);
    }
  }
</style>

{#if $tutoStep === +step}
  <div class="tutorial-box" use:tutorialPopperDirective>
    <div class="tutorial-content">
      {@html contentNode}
    </div>
    <div class="tutorial-steps">
      {$tutoStep + 1} / {$tutoSize}
      <FLIconButton
        icon={$tutoStep + 1 < $tutoSize ? 'keyboard_arrow_right' : 'close'}
        on:click={nextTutorial} />
    </div>
  </div>
  <div class="emphasis-scrim" class:emphasis>
    <div class="focus-node" bind:this={focusNode} on:click={nextTutorial} />
  </div>
{/if}
