<script>
  export const config = {
    title: 'Relatórios',
    noDrawer: true,
    backButton: true,
    callBackButton() {
      history.pushRouteLink('/panel')
    },
  }
</script>

<style>
  .title {
    margin: 1.5rem 0px;
    padding: 0px 1.5rem;
  }
</style>

<section>
  <h3 class="title">Algo deu errado. Por favor tente mais tarde.</h3>
</section>
