<script>
  import { onMount } from 'svelte'

  // Stores
  import {
    loading,
    welcome,
    config,
    user,
    offAccount,
  } from 'mapee-stories/main'
  import { route, urlData } from 'mapee-stories/router'
  import { userData, userMeta } from 'mapee-stories/live-data'

  // Components
  import 'mapee-components/single/mapee-colors.svelte'
  import AppLayout from 'mapee-components/complex/layout-components/app-layout.svelte'
  import Welcome from 'mapee-components/simple/welcome-cards.svelte'
  import Loading from 'mapee-components/single/loading.svelte'

  offAccount.set(
    firebase.auth().onAuthStateChanged(function(authUser) {
      user.set(authUser)
      loading.set(false)

      if (authUser && authUser.uid && $welcome === -2) {
        $welcome = 0
      }

      if (
        authUser &&
        authUser.uid &&
        ['/auth', '/'].includes($urlData.segment)
      ) {
        history.replaceRoute('/home')
      } else if (!authUser && !['/auth'].includes($urlData.segment)) {
        history.replaceRoute('/auth')
      }
    })
  )
</script>

<style>
  :global(:root) {
    --app-bar-height: 56px;
  }

  :global(body) {
    background-color: var(--bg, #eee);
    color: var(--on-surface);
  }

  :global(.sr-only) {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  :global(*:focus) {
    outline-color: var(--accent);
  }

  /* Desktop Large */
  @media screen and (min-width: 1024px) {
  }
  /* Desktop */
  @media screen and (min-width: 990px) {
  }
  /* Tablet */
  @media screen and (min-width: 768px) {
  }
  /* Mobile Landscape */
  @media screen and (min-width: 480px) {
  }
</style>

<AppLayout />
<Welcome bind:step={$welcome} />
<Loading />
