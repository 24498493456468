<script>
  import { scoreIndex } from 'mapee-helpers/crisis-helper'
  import ScoreChip from './score-chip.svelte'

  export let score = 0
  export let selected = false
  export let inapplicable = false

  $: index = scoreIndex(score)
</script>

<style>
  button {
    display: flex;
    font-size: 1rem;
    border: none;
    background: transparent;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    margin: 0;
    text-align: start;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }

  button:focus {
    outline: 2px solid currentColor;
  }

  span {
    margin-left: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .selected.score-0 {
    background-color: var(--mapee-orange-50);
  }
  .selected.score-1 {
    background-color: var(--mapee-yellow-50);
  }
  .selected.score-2 {
    background-color: var(--mapee-green-50);
  }
  .selected.score-3 {
    background-color: var(--mapee-blue-50);
  }
  .inapplicable {
    text-decoration: line-through;
  }
</style>

<button on:click class:selected class:inapplicable class="score-{index}">
  <ScoreChip semaphore={score}>{(score || 0).toFixed(1)}</ScoreChip>
  <span>
    <slot />
  </span>
</button>
