<script>
  import { onMount, createEventDispatcher } from 'svelte'
  import CrisisItem from './crisis-item.svelte'
  import FLButton from 'fl-components/single/fl-button.svelte'

  // Stories
  import { crisisQuestions } from 'mapee-stories/live-data'

  const dispatch = createEventDispatcher()

  export let diagId
  export let currentItem = {}

  export let list = []
  let selectedIndex = -1

  let renderList = []

  $: if ($crisisQuestions.length > 0 && list.length > 0) {
    renderList = $crisisQuestions
      .map(p => {
        let data = p.data()
        let q = list.find(q => q.name === data.name)

        if (!q) return {}

        let res = Object.assign(q, {
          score: Math.cbrt(q.gravity * q.urgency * q.future),
          synthesis: data.crisisData.synthesis,
          comment: data.crisisData.comment,
          crisisOrder: data.crisisOrder,
        })
        return res
      })
      .sort((a, b) => {
        let aScore = a.score
        let bScore = b.score

        if (aScore === 0) aScore = 6
        if (bScore === 0) bScore = 6

        if (a.status === 'inapplicable') aScore++
        if (b.status === 'inapplicable') bScore++

        return aScore - bScore
      })
  }

  function handleClick(item, index) {
    currentItem = item
    selectedIndex = index
  }
</script>

<style>
  .crisis-items {
    background-color: var(--surface);
    padding: 0.5rem;
    box-sizing: border-box;
    border-radius: 0.25rem;
    position: relative;
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  header {
    position: sticky;
    top: -0.5rem;
    background-color: var(--surface);
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 1.5rem 1.5rem 1rem;
    box-sizing: border-box;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }

  header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--on-surface);
    opacity: 0.12;
  }

  ul {
    padding: 0.5rem 0;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
  }
</style>

<div class="crisis-items">
  <header>
    <FLButton
      outline
      fill
      on:click={() => history.pushRouteLink(`/diag?d=${diagId}`)}>
      <i class="material-icons" slot="left">edit</i>
      Editar relatório inteiro
    </FLButton>
    <FLButton outline fill isAccent on:click={() => dispatch('callPrint')}>
      <i class="material-icons" slot="left">print</i>
      Imprimir
    </FLButton>
  </header>
  {#if !renderList.every(e => e.status === 'none')}
    <section>
      <ul>
        {#each renderList as item, index (item.name)}
          <li>
            <CrisisItem
              score={item.score}
              inapplicable={item.status === 'inapplicable'}
              selected={selectedIndex === index}
              on:click={() => handleClick(item, index)}>
              {item.synthesis}
            </CrisisItem>
          </li>
        {/each}
      </ul>
    </section>
  {/if}
</div>
