<script>
  import { onMount, onDestroy, createEventDispatcher } from 'svelte'
  import { slide } from 'svelte/transition'
  import FLButton from 'fl-components/single/fl-button.svelte'

  const dispatch = createEventDispatcher()

  export let index
  export let stepperList
  export let title
  export let question
  export let answers
  export let colors
  export let beforeValue
  export let value

  let open = false
  let openable = false

  $: editable = value > 0
  $: if (index <= 0 && value <= 0) {
    open = true
  }
  $: if (index <= 0 && value > 0) {
    open = false
  }
  $: if (index <= 0) {
    openable = true
  } else if (Number.isInteger(beforeValue)) {
    openable = beforeValue > 0
  } else {
    openable = false
  }

  function callTrigger(ev) {
    if (openable) stepperList[index].setOpen(index)
  }

  function callAnswerValue(val) {
    dispatch('click', val)
    if (stepperList[index + 1]) stepperList[index + 1].setOpen(index + 1)
    else stepperList[index].setOpen(index, false)
  }

  onMount(() => {
    stepperList[index] = {
      get open() {
        return open
      },
      set open(v) {
        open = v
      },
      setOpen(index, value = true) {
        stepperList.forEach((s, i) => {
          if (i === index) s.open = value
          else s.open = false
        })
      },
      get value() {
        return value
      },
    }

    if (openable && value <= 0) stepperList[index].setOpen(index, true)
  })

  onDestroy(() => {
    stepperList[index] = null
  })
</script>

<style>
  .trigger {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4.5rem;
    padding: 1rem;
    box-sizing: border-box;
    background: none;
    border: none;
    position: relative;
    user-select: none;
  }

  .trigger-text {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 0;
    color: var(--on-surface);
  }

  .trigger-divider {
    position: absolute;
    left: 1rem;
    width: 1.5rem;
    height: 1rem;
    display: flex;
    justify-content: center;
  }

  .trigger-divider-top {
    top: 0px;
  }

  .trigger:first-of-type .trigger-divider-top {
    display: none;
  }

  .trigger-divider-bot {
    bottom: 0px;
  }

  .trigger:last-of-type .trigger-divider-bot {
    display: none;
  }

  .avatar {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--accent);
    color: var(--on-accent);
    border-radius: 9999px;
    margin-inline-end: 1rem;
  }

  .avatar.disabled {
    background: rgba(0, 0, 0, 0.38);
  }

  i {
    font-size: 1rem;
  }

  ul {
    list-style-type: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-inline-start: 0px;
  }

  .step-content-collapse {
    display: flex;
    padding: 0 1rem;
  }

  .vertical-divider {
    min-width: 1.5rem;
    min-height: 100%;
    display: flex;
    justify-content: center;
    margin-inline-end: 1rem;
  }

  .divider-line {
    min-width: 1px;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.12);
  }

  .step-answers {
    flex: 1 1 auto;
    padding-bottom: 1rem;
  }

  .trigger:last-of-type + .step-content-collapse .divider-line {
    display: none;
  }
</style>

<!-- TODO: criar trigger -->
<button class="trigger" on:click={callTrigger}>
  <div class="trigger-divider trigger-divider-top">
    <div class="divider-line" />
  </div>
  <div class="avatar type-caption" class:disabled={!openable}>
    {#if editable}
      <i class="material-icons" aria-hidden="true">edit</i>
    {:else}{index + 1}{/if}
  </div>
  <div class="trigger-text type-body-2">
    {title}
    {#if value > 0}
      <span class="type-caption">{answers[value - 1]}</span>
    {/if}
  </div>
  <div class="trigger-divider trigger-divider-bot">
    <div class="divider-line" />
  </div>
</button>

<!-- TODO: adicionar collapse -->
{#if open}
  <div transition:slide|local class="step-content-collapse">
    <div class="vertical-divider">
      <div class="divider-line" />
    </div>
    <div class="step-answers">
      <p>{question}</p>
      <ul>
        {#each answers as answer, i (answer)}
          <li>
            <FLButton
              color="white"
              bgcolor={colors[i]}
              on:click={() => callAnswerValue(i + 1)}>
              {answer}
            </FLButton>
          </li>
        {/each}
      </ul>
    </div>
  </div>
{/if}
