<script>
  export const config = {
    title: 'Exemplo',
  }
</script>

<style>
  section {
    display: flex;
    flex-direction: column;
  }

  .aspect {
    height: 0;
    padding-top: calc(9 / 16 * 100%);
    position: relative;
  }

  .aspect .view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
</style>

<section>
  <div class="aspect">
    <div class="view">
      <iframe
        title="Video tutorial MAPEE 2"
        src="https://www.youtube.com/embed/fiTL7CGfV0k"
        frameborder="0"
        allow="encrypted-media;"
        allowfullscreen />
    </div>
  </div>
</section>
