import slugify from 'slugify'

slugify.extend({
  '#': 'tag',
  '@': 'em',
  '+': 'mais',
  '-': 'menos',
  $: 'real',
  '&': 'e',
})

/**
 *
 * @param {String} val
 *
 * @returns {String}
 */
export function slugifyConfigured(val) {
  return slugify(val, {
    replacement: '_',
    lower: true,
    remove: /[*~.()'"!?´`^:]/,
  })
}

/**
 * @param {String} key
 * @param {Writable} store
 *
 * @returns {Writable}
 */
export function useLocalStorage(key, store, expires = false) {
  let val = window.localStorage.getItem(`__FLUIDO_GLOBAL_STORE::${key}`)
  if (val) {
    val = JSON.parse(val)
    if (
      val.value !== undefined &&
      val.value !== null &&
      (!val.expires || val.expires > Date.now())
    ) {
      store.set(val.value)
    }
  }

  store.subscribe(state => {
    window.localStorage.setItem(
      `__FLUIDO_GLOBAL_STORE::${key}`,
      JSON.stringify({ value: state, expires })
    )
  })

  return store
}

export function preventPropagation(ev) {
  ev.preventDefault()
  ev.stopPropagation()
  return false
}

const hashCharacters =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

/**
 * @param {Integer} size
 * @returns {String}
 */
export function createHash(size) {
  if (size < 1) size = 1
  let res = ''
  for (let i = 0; i < size; i++) {
    let pos = Math.floor(Math.random() * hashCharacters.length)
    res += hashCharacters[pos]
  }

  return res
}

/**
 * @param {String} url
 * @param {String} path
 * @param {{method:'GET'|'POST'|'PUT'|'DELETE',token:String,data:Object,responseType:'object'|'json'|'blob'|'binary'|'string'}}opt
 */
export async function fetchBase(url, opt) {
  const { method, token, data, responseType } = Object.assign(
    {
      method: 'GET',
      data: {},
      token: '',
      responseType: 'object',
    },
    opt
  )

  const query = {
    method: method, // *GET, POST, PUT, DELETE, etc.
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // mode: 'no-cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    redirect: 'follow', // manual, *follow, error
  }

  if (token) {
    query.headers.Authorization = 'Bearer ' + token
  }
  if (method !== 'GET' && data) {
    query.body = JSON.stringify(data)
  }

  console.log(
    `%c${method} %c${url}`,
    'color:#f41; font-weight:bold',
    'font-weight:bold'
  )
  const response = await fetch(url, query)

  const promises = [response.status]

  switch (responseType) {
    case 'string':
      promises.push(response.text())
      break
    case 'blob':
    case 'binary':
      promises.push(response.blob())
      break
    case 'object':
    case 'json':
    default:
      promises.push(response.json())
  }

  return Promise.all(promises)
}

/**
 *
 * @param {Blob} blob
 * @param {String} filename
 */
export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')

  a.href = url
  a.download = filename || 'download'
  a.click()
  URL.revokeObjectURL(url)
}

/**
 *
 * @param {Blob} blob
 * @param {String} filename
 */
export function createBlobLink(blob, filename) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')

  a.href = url
  a.download = filename || 'download'
  a.innerHTML = `<i class="material-icons">save_alt</i> ${filename ||
    'download'}`
  return a
}

export const regexpEMail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
