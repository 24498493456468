<script>
  import { onDestroy } from 'svelte'
  import WelcomeCard from './welcome-card.svelte'
  import CardBG1 from './card-bg-1.svelte'
  import CardBG2 from './card-bg-2.svelte'
  import CardBG3 from './card-bg-3.svelte'
  import CardBG4 from './card-bg-4.svelte'
  import CardBG5 from './card-bg-5.svelte'

  let cards = []
  let listener = firebase
    .firestore()
    .doc('statics/welcome')
    .onSnapshot(doc => {
      if (doc.exists) {
        cards = doc.data().data || []
      }
    })

  export let step = 0
  $: currentCard = cards[step]

  function handleNext() {
    step++
  }
  function handlePrev() {
    step--
  }
  function handleSkipWelcome() {
    step = -1
  }
  function handleKeyDown(ev) {
    switch (ev.code) {
      case 'ArrowRight':
        if (step < 4) {
          handleNext()
        }
        break
      case 'ArrowLeft':
        if (step > 0) {
          handlePrev()
        }
        break
      case 'Escape':
        handleSkipWelcome()
        break
      default:
        break
    }
  }

  $: if (step >= 0 && step < 5) {
    document.addEventListener('keydown', handleKeyDown)
  } else {
    document.removeEventListener('keydown', handleKeyDown)
  }

  let cardBG = CardBG5

  $: switch (step) {
    case 0:
      cardBG = CardBG1
      break
    case 1:
      cardBG = CardBG2
      break
    case 2:
      cardBG = CardBG3
      break
    case 3:
      cardBG = CardBG4
      break
    default:
      cardBG = CardBG5
  }

  onDestroy(() => {
    if (listener) listener()
  })
</script>

<style>
  .welcome-cards {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .scrim {
    background-color: #000;
    opacity: 0.32;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    animation: fadeScrim 90ms linear both;
  }

  @keyframes fadeScrim {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.32;
    }
  }
</style>

{#if currentCard}
  <div class="welcome-cards">
    <div class="scrim" />
    <WelcomeCard
      on:skip-welcome={handleSkipWelcome}
      on:next-card={handleNext}
      on:prev-card={handlePrev}
      {...currentCard}
      id={step}
      total={cards.length}>
      <svelte:component this={cardBG} />
    </WelcomeCard>
  </div>
{/if}
