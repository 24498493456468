<script>
  import routeLink from 'mapee-scripts/route-link'

  export let id
  export let link
  export let title
  export let description
  export let progress
  export let score
  export let autoFocus = false

  function autoFocusDirective(node) {
    if (autoFocus) node.focus()
    return function() {}
  }
</script>

<style>
  a {
    display: grid;
    grid-template-columns: auto 1fr 40px 40px;
    grid-column-gap: 1rem;
    grid-template-rows: auto auto;
    grid-template-areas:
      'avatr title progr score'
      'avatr descr progr score';
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 1rem;
  }

  .avatar {
    grid-area: avatr;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 9999px;
    background: var(--primary);
    color: var(--on-primary);
    text-transform: uppercase;
    font-weight: 500;
  }

  h3 {
    grid-area: title;
    margin: 0px;
    line-height: 1.25rem;
  }

  p {
    grid-area: descr;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--on-surface-secondary);
  }

  .progress {
    grid-area: progr;
  }

  .score {
    grid-area: score;
  }
</style>

<a href={link} use:routeLink use:autoFocusDirective>
  <div class="avatar type-caption" aria-hidden="true">{id}</div>
  <h3 lang="en" class="type-body" aria-label="{id}, {title}">{title}</h3>
  <p class="type-body-2">{description}</p>
  <div
    class="progress"
    aria-label="{progress[0]} de {progress[1]} questões respondidas.">
    {progress.join('/')}
  </div>
  <div class="score" aria-label="placar atual: {score}">{score}</div>
</a>
