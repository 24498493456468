<script>
  // Scripts
  import routeLink from 'mapee-scripts/route-link'
  import FLTooltip from './fl-tooltip.svelte'

  export let text = false
  export let outline = false
  export let disabled = false
  export let fill = false

  export let ariaLabel = ''
  export let node = null

  export let isAccent = false
  export let color = ''
  export let bgcolor = ''

  export let type = 'button' // 'submit' or 'link'
  export let href = ''

  export let className = ''

  export let tooltip = ''
</script>

{#if type !== 'link'}
  <button
    {disabled}
    {href}
    {type}
    on:click
    bind:this={node}
    aria-label={ariaLabel}
    class="type-button fl-button {className}"
    class:fill
    class:is-accent={isAccent}
    class:text-button={text}
    class:outline-button={outline}
    style="--fl-button-color: {color}; --fl-button-bg-color: {bgcolor}"
  >
    <div class="button-bg" />
    <div class="button-text">
      <div class="left">
        <slot name="left" />
      </div>
      <slot />
      <div class="right">
        <slot name="right" />
      </div>
    </div>
  </button>
{:else}
  <a
    {disabled}
    {href}
    on:click
    bind:this={node}
    aria-label={ariaLabel}
    use:routeLink
    class="type-button fl-button {className}"
    class:fill
    class:is-accent={isAccent}
    class:text-button={text}
    class:outline-button={outline}
    style="--fl-button-color: {color}; --fl-button-bg-color: {bgcolor}"
  >
    <div class="button-bg" />
    <div class="button-text">
      <div class="left">
        <slot name="left" />
      </div>
      <slot />
      <div class="right">
        <slot name="right" />
      </div>
    </div>
  </a>
{/if}
{#if !!tooltip}
  <FLTooltip text={tooltip} />
{/if}

<style>
  a,
  button {
    --fl-button-color: var(--on-primary, #fff);
    --fl-button-bg-color: var(--primary, #121212);
    background-color: transparent;
    border: none;
    color: var(--custom-fl-button-color, var(--fl-button-color));
    user-select: none;
    position: relative;
    padding: 0.125rem 1rem;
    cursor: pointer;
    text-decoration: none;
  }

  a.is-accent,
  button.is-accent {
    --fl-button-color: var(--on-accent, #fff);
    --fl-button-bg-color: var(--accent, #121212);
  }

  .fill {
    width: 100%;
  }

  .button-bg {
    background-color: var(
      --custom-fl-button-bg-color,
      var(--fl-button-bg-color)
    );
    border-radius: 0.25rem;
    box-sizing: border-box;
    position: absolute;
    top: 0.125rem;
    right: 0px;
    bottom: 0.125rem;
    left: 0px;
  }

  .button-text {
    position: relative;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 2.5rem;
  }

  .button-text .left {
    margin-right: 0.5rem;
  }
  .button-text .right {
    margin-left: 0.5rem;
  }
  .button-text .left,
  .button-text .right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-text .left:empty,
  .button-text .right:empty {
    display: none;
  }

  .text-button {
    border: none;
    color: var(--custom-fl-button-bg-color, var(--fl-button-bg-color));
  }

  .text-button .button-bg {
    background-color: transparent;
  }

  .outline-button {
    color: var(--custom-fl-button-bg-color, var(--fl-button-bg-color));
  }

  .outline-button .button-bg {
    background-color: transparent;
    border: 2px solid currentColor;
  }

  [disabled='true'],
  [disabled=''] {
    opacity: 0.36;
    cursor: not-allowed;
  }
</style>
