<script>
  import { scoreIndex } from 'mapee-helpers/crisis-helper'
  export let item
  export let questionData

  let text = ''
  let index = 0

  $: if (item && item.score && questionData) {
    let list = questionData.data().crisisData.score
    index = scoreIndex(item.score)
    let e = list[index]
    text = e.replace(
      '%%VALUE%%',
      `<span class="highlight-score">${item.score.toFixed(1)}</span>`
    )
  } else {
    text = ''
    index = 0
  }
</script>

<style>
  .understand-score {
    padding: 1.5rem 1rem;
    color: #121212;
  }
  h3 {
    margin-bottom: 0.5rem;
  }

  .score-0 {
    background-color: var(--mapee-orange-50);
  }
  .score-1 {
    background-color: var(--mapee-yellow-50);
  }
  .score-2 {
    background-color: var(--mapee-green-50);
  }
  .score-3 {
    background-color: var(--mapee-blue-50);
  }

  :global(.highlight-score) {
    font-weight: 700;
  }
  .score-0 :global(.highlight-score) {
    color: var(--bad);
  }
  .score-1 :global(.highlight-score) {
    color: var(--average);
  }
  .score-2 :global(.highlight-score) {
    color: var(--good);
  }
  .score-3 :global(.highlight-score) {
    color: var(--best);
  }
</style>

<section class="understand-score type-body-2 score-{index}">
  <h3 class="type-subtitle-2">Entenda sua nota</h3>
  {#if 'finished' === item.status}
    <p class="type-body-2">
      {@html text}
    </p>
  {:else if 'inapplicable' === item.status}
    <p class="type-body-2">Você marcou essa pergunta como não se aplica!</p>
  {:else}
    <p class="type-body-2">Esta pergunta não foi respondida.</p>
  {/if}
</section>
