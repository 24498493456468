<script>
  import { onMount, onDestroy } from 'svelte'

  // Helpers
  import {
    gravityChartLabels,
    urgencyChartLabels,
    futureChartLabels,
  } from 'mapee-helpers/consts'
  import { createHash } from 'mapee-helpers'
  import { callCreateDiagnostic } from 'mapee-helpers/diagnostic-helper'

  // Components
  import TutorialBox from 'mapee-components/single/tutorial-box.svelte'
  import FLButton from 'fl-components/single/fl-button.svelte'
  import FLIconButton from 'fl-components/single/fl-icon-button.svelte'

  // Stores
  import { projectDoc, questions } from 'mapee-stories/live-data'
  import { tutoStep } from 'mapee-stories/tutorial'

  export const config = {
    title: 'Comece aqui',
  }

  function callStartTutorial() {
    history.pushRoute('/panel')
    tutoStep.set(0)
  }
</script>

<section class="page">
  <div class="placeholder">
    <header class="placeholder-header">
      <h3 class="type-h4">Comece um diagnóstico</h3>
    </header>
    <div class="placeholder-content">
      <section class="placeholder-intro">
        <p>
          Clicando em
          <strong>Questionário</strong>
          faça seu primeiro diagnóstico ou outros (no botão +).
        </p>
        <p>
          Preenchido o
          <strong>Mapee</strong>
          veja o
          <strong>Resultado</strong>
          em relatórios que vão muito além do planejamento estratégico.
        </p>
        <p>
          <strong>Vencendo a crise</strong>
          é o Relatório do
          <strong>Mapee</strong>
          sobre a empresa diante da
          <strong>Covid-19</strong>
          .
        </p>
        <p>
          Veja no
          <strong>Exemplo</strong>
          um vídeo sobre o modo de perguntar do
          <strong>Mapee</strong>
          .
        </p>
        <p>
          O
          <strong>Passo a passo</strong>
          também vai te ajudar na aplicação do nosso método.
        </p>
      </section>

      <section class="placeholder-img-section">
        <p>
          Conheça mais o método obtendo seu livro gratuitamente em
          <a
            target="_blank"
            href="https://www.amazon.com.br/Conquistar-melhores-resultados-nas-empresas-ebook/dp/B08166527M"
          >
            amazon.com
          </a>
        </p>
        <img
          width="640"
          height="520"
          src="/images/mapee-book-small.png"
          alt="Capa do livro Manual para conquistar melhores resultados nas
          empresas"
        />
      </section>

      <div class="placeholder-actions">
        <FLButton disabled on:click={callStartTutorial}>
          começar introdução
        </FLButton>
        <FLButton
          text
          on:click={() => callCreateDiagnostic($projectDoc, $questions)}
        >
          pular introdução
        </FLButton>
      </div>
    </div>
  </div>
</section>

<style>
  section.page {
    font-size: 1rem;
    padding-bottom: 4rem;
    outline: none;
    /* opacity: 0;
    transition: opacity 0.3s; */
  }

  /* section.page.load {
    opacity: 1;
  } */

  .placeholder {
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    max-width: 60rem;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .placeholder-header,
  .placeholder-content {
    background-color: var(--surface);
    border-radius: 0.25rem;
  }

  .placeholder-header {
    padding: 1.5rem 1rem;
  }

  .placeholder-content {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content auto;
    overflow: hidden;
  }

  .placeholder-intro {
    padding: 1.5rem 1rem;
  }

  .placeholder-intro p {
    margin-bottom: 0.5em;
  }

  .placeholder-actions {
    padding: 1.5rem 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    place-self: start;
  }

  .placeholder-img-section {
    grid-row: span 2;
    display: flex;
    flex-direction: column;
  }

  .placeholder-img-section p {
    padding: 1.5rem;
  }

  .placeholder-img-section a {
    color: var(--accent);
  }

  .placeholder-img-section img {
    width: 100%;
    height: auto;
  }
</style>
