<script>
  export let icon = ''
  export let ariaLabel = ''
  export let node = null
  export let disabled = false
</script>

<style>
  button {
    color: var(--on-surface);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 100000000px;
    flex-shrink: 0;
  }

  button:focus::before {
    content: '';
    display: block;
    background-color: currentColor;
    opacity: 0.12;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
  }

  i {
    padding: 0px;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    color: var(--on-surface-secondary);
  }

  button:disabled i {
    color: var(--on-surface-hint);
  }
</style>

<button
  type="button"
  {disabled}
  aria-label={ariaLabel}
  on:click
  bind:this={node}>
  <slot>
    <i class="material-icons" aria-hidden="true">{icon}</i>
  </slot>
</button>
