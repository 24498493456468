<script>
  let focus = false
  export let fullWidth = false
</script>

<style>
  button {
    border: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
    appearance: none;
    cursor: pointer;
    outline: none;
    color: inherit;
  }

  button:active {
    background-color: transparent;
  }

  .full-width {
    width: 100%;
  }
</style>

<button
  class:full-width={fullWidth}
  on:click
  on:focus={() => (focus = true)}
  on:blur={() => (focus = false)}>
  <slot {focus} />
</button>
