<script>
  import { onMount } from 'svelte'
  // Scripts
  import lottieDirective from 'mapee-scripts/lottie'
  import { delay } from 'mapee-helpers/timer'
  // Stories
  import { loading, offAccount } from 'mapee-stories/main'

  export const config = {
    noDrawer: true,
  }

  const lottieOptions = {
    path: '/animations/crying.json',
    renderer: 'svg',
    loop: true,
    autoplay: true,
    name: 'Thanks',
  }

  onMount(() => {
    let firestore = firebase.firestore()

    firestore
      .waitForPendingWrites()
      .then(() => firestore.terminate())
      .then(() => firestore.clearPersistence())
      .then(() => delay(2000))
      .then(() => loading.set(true))
      .then(() => delay(500))
      .then(() => $offAccount())
      .then(() => firebase.auth().signOut())
      .then(() => history.replaceRoute('/auth'))
      .then(() => location.reload())
  })
</script>

<style>
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .animation {
    width: 16rem;
    height: 16rem;
  }
</style>

<section>
  <div class="animation" use:lottieDirective={lottieOptions} />
  <p class="type-h4">Até breve!</p>
</section>
