<script>
  import { questions, crisisQuestions } from 'mapee-stories/live-data'

  // Components
  import Button from 'fl-components/single/fl-button.svelte'
  import Modal from 'fl-components/simple/fl-modal.svelte'
  import IndeterminateLoading from 'fl-components/single/fl-indeterminate-loading.svelte'

  import resCrisis from '../../respostas-tematicas.js'

  export const config = {
    title: 'Edição de questões',
    noDrawer: true,
  }

  const firestore = firebase.firestore()
  const FieldValue = firebase.firestore.FieldValue

  let questionToEdit = false
  let questionForm = {}

  let formLoading = false

  function selectQuestion(question) {
    questionToEdit = question
    let data = question.data()
    questionForm = {
      question: data.question || '',
      synthesis: data.synthesis || '',
      description: data.description || '',
    }
  }

  function saveQuestion() {
    if (questionToEdit) {
      formLoading = true
      questionToEdit.ref.set(questionForm, { merge: true }).then(() => {
        formLoading = false
        questionToEdit = false
        questionForm = {}
      })
    } else {
      questionToEdit = false
    }
  }

  async function fixQuestions() {
    // await Promise.all(
    //   $questions.map(q => {
    //     // console.log(q.id)
    //     // Correções a parte das questões
    //     return q.ref
    //       .set(
    //         {
    //           crisisData: FieldValue.delete(),
    //           crisis: false,
    //           crisisOrder: -1,
    //         },
    //         { merge: true }
    //       )
    //       .then(() => console.log(q.data().name))
    //   })
    // )
    // resCrisis.forEach((qt, index) => {
    //   console.log('----', qt.id, '----')
    //   let data = {
    //     comment: qt.comment.replace(/\s+/g, ' '),
    //     synthesis: qt.synthesis.replace(/\s+/g, ' '),
    //     score: [
    //       qt.s0.replace(/\s+/g, ' '),
    //       qt.s1.replace(/\s+/g, ' '),
    //       qt.s2.replace(/\s+/g, ' '),
    //       qt.s3.replace(/\s+/g, ' '),
    //     ],
    //   }
    //   firestore
    //     .doc(`questions/${qt.id.toLowerCase()}`)
    //     .set(
    //       {
    //         crisisData: data,
    //         crisis: true,
    //         crisisOrder: index,
    //       },
    //       { merge: true }
    //     )
    //     .then(() => console.log(qt.id.toLowerCase()))
    // })
    // firestore
    //   .collection('questions')
    //   .where('crisis', '==', false)
    //   .get()
    //   .then(result => {
    //     result.docs.forEach(doc => {
    //       console.log(doc.id)
    //       doc.ref.set({ filter: [] }, { merge: true })
    //     })
    //   })
  }
</script>

<style>
  .question-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
    grid-gap: 0.5rem;
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
</style>

<Modal
  bind:open={questionToEdit}
  on:submit={saveQuestion}
  bind:locked={formLoading}
  title="Editar questão">
  <label>
    <span>Questão</span>
    <textarea rows="8" bind:value={questionForm.question} />
  </label>
  <label>
    <span>Síntese</span>
    <input type="text" bind:value={questionForm.synthesis} />
  </label>
  <label>
    <span>Descritivo</span>
    <textarea rows="8" bind:value={questionForm.description} />
  </label>
  <!-- <label>
    <span>Comentário</span>
    <textarea rows="8" bind:value={questionForm.comment} />
  </label>
  <label>
    <span>Score Ruim</span>
    <textarea rows="8" bind:value={questionForm.scoreBad} />
  </label>
  <label>
    <span>Score Médio</span>
    <textarea rows="8" bind:value={questionForm.scoreAverage} />
  </label>
  <label>
    <span>Score Bom</span>
    <textarea rows="8" bind:value={questionForm.scoreGood} />
  </label>
  <label>
    <span>
      <input type="checkbox" bind:checked={questionForm.crisis} />
      Questão de lucratividade
    </span>
  </label>
  {#if questionForm.crisis}
    <label>
      <span>Ordem da questão de lucratividade</span>
      <input type="number" min="1" bind:value={questionForm.crisisOrder} />
    </label>
  {/if} -->

  <div slot="actions">
    <Button
      text
      disabled={formLoading}
      on:click={() => (questionToEdit = false)}>
      cancelar
    </Button>
    <Button text disabled={formLoading} type="submit">
      {#if formLoading}
        <IndeterminateLoading />
      {:else}salvar{/if}
    </Button>
  </div>
</Modal>

<section>
  <!-- <Button isAccent outline on:click={fixQuestions}>Corrigir questões</Button> -->
  <div class="question-box">
    {#each $questions as question (question.id)}
      <Button on:click={() => selectQuestion(question)}>{question.id}</Button>
    {/each}
  </div>
</section>
