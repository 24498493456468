import { writable } from 'svelte/store'

import PageNotFound from '../routes/page404.svelte'

import Auth from '../routes/auth.svelte'
import Home from '../routes/home.svelte'
import Crisis from '../routes/crisis.svelte'
import Panel from '../routes/panel.svelte'
import ActionMap from '../routes/action-map.svelte'
import Changelog from '../routes/changelog.svelte'
import Diag from '../routes/diag.svelte'
import Exit from '../routes/exit.svelte'
import Feedback from '../routes/feedback.svelte'
import Profile from '../routes/profile.svelte'
import Tutorial from '../routes/tutorial.svelte'
import FeedbackSuccess from '../routes/feedback-success.svelte'

import QuestionEdit from '../routes/question-edit.svelte'

import OrderReportSuccess from '../routes/order-report-success.svelte'
import OrderReportFailed from '../routes/order-report-failed.svelte'

export const urlData = writable({
  segment: '/',
  query: {},
})

export const route = writable(null)

function changeRouteState() {
  const qmap = new URLSearchParams(location.search)
  let resQ = {}
  for (let qel of qmap.entries()) {
    resQ[qel[0]] = qel[1]
  }
  urlData.set({
    segment: location.pathname,
    query: resQ,
    tag: location.hash,
  })
}

changeRouteState()

function buildUrl(url, query, tag) {
  let cUrl = url
  if (query) {
    cUrl += '?'
    let k = Object.entries(query)
    cUrl += k.map(e => e.join('=')).join('&')
  }
  if (tag) cUrl += '#' + tag
  return cUrl
}

export function replaceRouteLink(url) {
  history.replaceState({}, '', url)
  window.dispatchEvent(new CustomEvent('fl-router'))
}
export function pushRouteLink(url) {
  history.pushState({}, '', buildUrl(url))
  window.dispatchEvent(new CustomEvent('fl-router'))
}

export function replaceRoute(url, query, tag) {
  replaceRouteLink(buildUrl(url, query, tag))
}
export function pushRoute(url, query, tag) {
  pushRouteLink(buildUrl(url, query, tag))
}

history.replaceRouteLink = replaceRouteLink
history.pushRouteLink = pushRouteLink
history.replaceRoute = replaceRoute
history.pushRoute = pushRoute

window.addEventListener('popstate', changeRouteState)
window.addEventListener('fl-router', changeRouteState)

let lastSegment = '/'
urlData.subscribe(function($urlData) {
  if ($urlData.segment !== lastSegment) {
    lastSegment = $urlData.segment
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  switch ($urlData.segment) {
    case '/':
      route.set(null)
      break
    case '/auth':
      route.set(Auth)
      break
    case '/home':
      route.set(Home)
      break
    case '/crisis':
      route.set(Crisis)
      break
    case '/panel':
      route.set(Panel)
      break
    case '/actionmap':
      route.set(ActionMap)
      break
    case '/changelog':
      route.set(Changelog)
      break
    case '/diag':
      route.set(Diag)
      break
    case '/exit':
      route.set(Exit)
      break
    case '/feedback':
      route.set(Feedback)
      break
    case '/profile':
      route.set(Profile)
      break
    case '/tutorial':
      route.set(Tutorial)
      break
    case '/feedback-success':
      route.set(FeedbackSuccess)
      break
    case '/question-edit':
      route.set(QuestionEdit)
      break
    case '/report_payment_success':
      route.set(OrderReportSuccess)
      break
    case '/report_payment_failed':
      route.set(OrderReportFailed)
      break
    default:
      route.set(PageNotFound)
  }
})
