export const chartColors = [
  '#C65329',
  '#D47B27',
  '#E2A325',
  '#8DA417',
  '#377296',
]
// export const chartColors = [
//   '#e46030',
//   '#ff915c',
//   '#eeeeee',
//   '#51936b',
//   '#216540',
// ]

export const gravityChartLabels = [
  'Extremamente grave',
  'É grave',
  'Média gravidade',
  'Pouco grave',
  'Sem gravidade',
]
export const urgencyChartLabels = [
  'Extremamente urgente',
  'Com urgência',
  'Média urgência',
  'Pouco urgente',
  'Sem urgência',
]
export const futureChartLabels = [
  'Já está piorando',
  'Piorar em pouco tempo',
  'Piorar em médio prazo',
  'Piorar num longo prazo',
  'Ficar bem',
]

export const stepperQuestions = [
  {
    title: 'Gravidade',
    name: 'gravity',
    question: 'Sobre esta questão, como é percebida a situação da sua empresa?',
    answers: gravityChartLabels,
  },
  {
    title: 'Urgência',
    name: 'urgency',
    question: 'Qual a necessidade de agir neste ponto no caso da sua empresa?',
    answers: urgencyChartLabels,
  },
  {
    title: 'Futuro',
    name: 'future',
    question:
      'Qual a tendência dessa questão sobre a empresa, sem uma ação intervindo nela?',
    answers: futureChartLabels,
  },
]

export const sitemap = [
  {
    route: '/home',
    selected: ['/home'],
    text: 'Começando',
    icon: 'home',
  },
  // {
  //   route: '/crisis',
  //   selected: ['/crisis'],
  //   text: 'Vencendo a Crise',
  //   icon: 'accessibility_new',
  //   emphasis: true,
  // },
  {
    route: '/panel',
    selected: ['/panel', '/'],
    text: 'Questionário',
    icon: 'pie_chart',
  },
  {
    route: '/actionmap',
    selected: ['/actionmap'],
    text: 'Resultados',
    icon: 'insert_drive_file',
    divider: true,
  },
  {
    route: '/profile',
    selected: ['/profile'],
    text: 'Perfil',
    icon: 'person',
  },
  {
    route: '/tutorial',
    selected: ['/tutorial'],
    text: 'Exemplo',
    icon: 'play_circle_filled',
  },
  {
    route: '/feedback',
    selected: ['/feedback'],
    text: 'Feedback',
    icon: 'feedback',
    divider: true,
  },
  {
    route: '/exit',
    selected: ['/exit'],
    text: 'Sair',
    icon: 'exit_to_app',
  },
]
