<script>
  import { onMount, afterUpdate } from 'svelte'
  import moment from 'moment'
  import AlertBox from 'mapee-components/single/alert-box.svelte'

  // Helpers
  import {
    mainCodes,
    subCodes,
    mainTitles,
    titles,
    costs,
    reportDisabled,
  } from 'mapee-helpers/report-helper'
  import routeLink from 'mapee-scripts/route-link'

  // Stories
  import { userMeta } from 'mapee-stories/live-data'

  // Components
  import FLIndeterminateLoading from 'fl-components/single/fl-indeterminate-loading.svelte'
  import FlButton from 'fl-components/single/fl-button.svelte'

  const firestore = firebase.firestore()

  export let diag
  export let typesFlag = []
  export let cost = 0
  let lastTypesFlag = []

  let showHiddenReports = false
  let questionsSize = 0
  let questionsFinished = 0

  $: if (diag && diag.exists) {
    let qs = diag.data().questions
    questionsFinished = qs.reduce((a, v) => {
      return v.status === 'finished' ? a + 1 : a
    }, 0)
    questionsSize = parseInt(qs.length / 2)
    showHiddenReports = questionsFinished >= questionsSize
  }

  function validField(item, field) {
    const data = item.data()

    return (
      data.reportPermission.includes(field) &&
      data.orderExpires &&
      data.orderExpires.toDate().getTime() >= Date.now()
    )
  }

  function calcCost(ud, cost) {
    if (ud.exists) {
      if (ud.data().professor) return 0
      if (ud.data().academic) return cost / 2
    }
    return cost
  }

  function calcTotalCost(ud, cp, tp) {
    let list = tp.filter(e => !cp.includes(e))
    let cost = list.reduce((a, v) => a + costs[v], 0)
    if (ud.exists) {
      if (ud.data().professor) return 0
      if (ud.data().academic) return cost / 2
    }
    return cost
  }

  let promise = firestore
    .doc(diag.ref.path.replace('diagnostics', 'diagnostics-meta'))
    .get()
    .then(item => {
      lastTypesFlag = [].concat(item.data().reportPermission)
      typesFlag = [].concat(item.data().reportPermission)
      return item
    })

  afterUpdate(() => {
    cost = calcTotalCost($userMeta, lastTypesFlag, typesFlag)
  })
</script>

<style>
  form {
    display: flex;
    flex-direction: column;
  }

  .report_payed {
    color: green;
    margin-left: auto;
  }

  label.disabled {
    opacity: 0.3;
  }

  .top-space {
    margin-top: 0.5rem;
  }

  label {
    display: grid;
    grid-template-columns: 2.5rem 1fr 5rem;
    align-items: baseline;
    padding: 0.25rem;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
</style>

{#await promise}
  <p class="type-body">
    Carregando...
    <FLIndeterminateLoading />
  </p>
{:then item}
  {#if item.data().orderExpires}
    <p class="type-body">
      Pagamento expira em {moment(item.data().orderExpires.toDate()).format('DD/MM/YYYY')}
    </p>
  {/if}
  <!-- <p class="type-subtitle-2">Relatórios:</p> -->
  <form class="type-body">
    <label>
      <input type="checkbox" checked disabled />
      <!-- Relatório gratuito -->
      <span>Notas na sequência das perguntas</span>
    </label>
    {#if $userMeta.exists && $userMeta.data().academic}
      {#each mainCodes as report, i}
        {#if Array.isArray(subCodes[i])}
          <p class="type-subtitle-2 top-space">{mainTitles[i]}:</p>
          {#each subCodes[i] as subReport}
            {#if costs[subReport] <= 0 || showHiddenReports}
              <label class:disabled={reportDisabled[i]}>
                {#if validField(item, subReport)}
                  <input type="checkbox" checked disabled value={subReport} />
                  <span>{titles[subReport]}</span>
                  <span class="report_payed">Pago</span>
                {:else}
                  <input
                    type="checkbox"
                    disabled={reportDisabled[i]}
                    bind:group={typesFlag}
                    value={subReport} />
                  <span>
                    {titles[subReport]}
                    {#if reportDisabled[i]}(Em breve){/if}
                  </span>
                  <span>
                    R$ {calcCost($userMeta, costs[subReport]).toFixed(2)}
                  </span>
                {/if}
              </label>
            {/if}
          {/each}
        {:else}
          <!-- <p class="type-caption">Contem {titles[report]}.</p> -->
          {#if costs[report] <= 0 || showHiddenReports}
            <label class:disabled={reportDisabled[i]}>
              {#if validField(item, report)}
                <input type="checkbox" checked disabled value={report} />
                <span>{mainTitles[i]}</span>
                <span class="report_payed">Pago</span>
              {:else}
                <input
                  type="checkbox"
                  disabled={reportDisabled[i]}
                  bind:group={typesFlag}
                  value={report} />
                <span>
                  {mainTitles[i]}
                  {#if reportDisabled[i]}(Em breve){/if}
                </span>
                <span>R$ {calcCost($userMeta, costs[report]).toFixed(2)}</span>
              {/if}
            </label>
          {/if}
        {/if}
      {/each}
    {:else}
      {#each mainCodes as report, i}
        {#if costs[report] <= 0 || showHiddenReports}
          <label class:disabled={reportDisabled[i]}>
            {#if validField(item, report)}
              <input type="checkbox" checked disabled value={report} />
              <span>{mainTitles[i]}</span>
              <span class="report_payed">Relatório Pago</span>
            {:else}
              <input
                type="checkbox"
                disabled={reportDisabled[i]}
                bind:group={typesFlag}
                value={report} />
              <span>
                Relatório {mainTitles[i]}
                {#if reportDisabled[i]}(Em breve){/if}
              </span>
              <span>R$ {calcCost($userMeta, costs[report]).toFixed(2)}</span>
            {/if}
          </label>
        {/if}
      {/each}
    {/if}
    {#if !showHiddenReports}
      <AlertBox state="warning">
        <p>
          Você respondeu menos de 50% do questionário. Para um melhor resultado
          nos relatórios responda mais {questionsSize - questionsFinished}.
        </p>
        <a
          class="type-button"
          href="javascript:void(0)"
          on:click={() => (showHiddenReports = true)}>
          Quero gerar relatórios mesmo assim
        </a>
      </AlertBox>
    {:else if !$userMeta.exists || !($userMeta.data().academic || $userMeta.data().professor)}
      <AlertBox>
        <p class="type-caption">Estudantes e professores tem 50% de desconto</p>
        <a class="type-button" href="/profile" use:routeLink>
          Preencha no perfil
        </a>
      </AlertBox>
    {/if}
  </form>
{/await}
