import { writable } from 'svelte/store'
import { useLocalStorage } from 'mapee-helpers'

export const loading = writable(true)
export const welcome = useLocalStorage(
  'welcome-flag',
  writable(-2),
  Date.now() + 24 * 60 * 60 * 1000
)
export const offAccount = writable(() => {})
export const screen = writable({})
export const config = writable({})
export const user = writable({})
