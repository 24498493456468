<script>
  import { createEventDispatcher } from 'svelte'
  import FLButton from 'fl-components/single/fl-button.svelte'
  import FLIconButton from 'fl-components/single/fl-icon-button.svelte'

  export let id
  export let total
  export let first = false
  export let last = false

  const dispatch = createEventDispatcher()

  function callNextCard() {
    dispatch('next-card')
  }
  function callPrevCard() {
    dispatch('prev-card')
  }
  function callSkipWelcome() {
    dispatch('skip-welcome')
  }
</script>

<style>
  footer {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .welcome-card-actions {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: auto;
  }

  .step-progress {
    padding: 0.5rem;
    line-height: 1.5rem;
    color: var(--on-surface-secondary);
  }

  .animate-button {
    animation: animateButton 600ms both cubic-bezier(0.4, 0, 0.2, 1);
    animation: animateButton 600ms both cubic-bezier(0.18, 0.89, 0.32, 1.28);
    will-change: transform;
    --button-bg: var(--primary);
    --button-color: var(--on-primary);
  }

  @keyframes animateButton {
    from {
      transform: scale(0.0001);
    }
    to {
      transform: scale(1);
    }
  }
</style>

<footer>
  <div class="welcome-card-actions">
    <FLIconButton
      on:click={callNextCard}
      ariaLabel="Próximo passo"
      icon="chevron_right" />
    <div class="step-progress type-body-1" aria-hidden="true">{id}/{total}</div>
    <FLIconButton
      on:click={callPrevCard}
      disabled={first}
      ariaLabel="Passo anterior"
      icon="chevron_left" />
  </div>
  {#if last}
    <div class="animate-button">
      <FLButton on:click={callSkipWelcome}>Começar</FLButton>
    </div>
  {:else}
    <FLButton on:click={callSkipWelcome}>Pular</FLButton>
  {/if}
</footer>
