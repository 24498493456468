<script>
  import { config, screen } from 'mapee-stories/main'

  let node
  let width

  $: back = !!$config.backButton
  $: noDrawer = !!$config.noDrawer

  function callBackButton() {
    if ($config.callBackButton) $config.callBackButton()
    if (!noDrawer) $screen.drawerOpen = true
  }
</script>

<style>
  .app-bar {
    grid-area: appbar;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 0 1rem;
    box-sizing: border-box;
    min-height: var(--app-bar-height);
    position: sticky;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--primary);
    color: var(--on-primary);
    z-index: 100;
  }
  button {
    display: block;
    box-sizing: border-box;
    background-color: transparent;
    color: currentColor;
    margin: 0px;
    border: none;
    padding: 1rem;
    height: 3.5rem;
    width: 3.5rem;
    outline: none;
  }
  h1,
  .appbar-title {
    margin: 0px;
    width: 100%;
    margin-left: 2rem;
    margin-right: auto;
  }

  /* @media screen and (min-width: 990px) { */
  @media screen and (min-width: 1024px) {
    button:not(.is-active) {
      display: none;
    }
    h1,
    .appbar-title {
      margin-left: 2rem;
    }
  }
</style>

<div class="app-bar" bind:clientWidth={width} bind:this={node}>
  {#if back || !noDrawer}
    <button
      on:click={callBackButton}
      class:is-active={back}
      type="button"
      aria-label={back ? 'Voltar para a janela principal' : 'Abrir menu lateral'}>
      <i class="material-icons" aria-hidden="true">
        {back ? 'arrow_back' : 'menu'}
      </i>
    </button>
  {/if}
  {#if typeof $config.title === 'string'}
    <h1 class="type-h6">{$config.title || 'MAPEE'}</h1>
  {:else}
    <div class="appbar-title">
      <svelte:component this={$config.title} />
    </div>
  {/if}
</div>
