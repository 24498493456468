<script>
  import { onMount, onDestroy } from 'svelte'

  // Stores
  import { user } from 'mapee-stories/main'

  // Helpers
  import { regexpEMail } from 'mapee-helpers'
  import { TimerTimeout } from 'mapee-helpers/timer'
  import lottieDirective from 'mapee-scripts/lottie'

  // Components
  import FLIndeterminateLoading from 'fl-components/single/fl-indeterminate-loading.svelte'
  import FLButton from 'fl-components/single/fl-button.svelte'
  import FLIconButton from 'fl-components/single/fl-icon-button.svelte'

  export const config = {
    noDrawer: true,
    backButton: true,
    callBackButton() {
      location.href = 'https://mapee.com.br/'
    },
    title: 'Login',
  }

  const auth = firebase.auth()
  const googleProvider = new firebase.auth.GoogleAuthProvider()
  const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com')

  let state = 'none'
  let loading = false

  let formEmail = ''
  let formDisplayName = ''
  let formPassword = ''
  let formConfirmPassword = ''
  let formEmailError = false
  let formLoginError = false
  let formRegisterError = false
  let formRegisterPasswordError = false
  let formRecoveryMessage = false
  let formRecoveryMessageError = false

  function resetFlags() {
    formEmailError = false
    formLoginError = false
    formRegisterError = false
    formRegisterPasswordError = false
    formRecoveryMessage = false
    formRecoveryMessageError = false
  }

  function mainButtonFormHandler(ev) {
    loading = true
    resetFlags()

    switch (state) {
      case 'none':
        formEmailError = !regexpEMail.test(formEmail)
        if (!formEmailError) {
          auth
            .fetchSignInMethodsForEmail(formEmail)
            .then(list => {
              loading = false
              if (list.length > 0) state = 'login'
              else state = 'register'
            })
            .catch(err => {
              loading = false
              formEmailError = true
              console.error(err)
            })
        } else loading = false
        break
      case 'login':
        auth
          .signInWithEmailAndPassword(formEmail, formPassword)
          .then(data => {
            if (!data) return Promise.reject('unknown_login_error')
          })
          .catch(err => {
            loading = false
            formLoginError = true
            console.error(err)
          })
        break
      case 'register':
        if (formPassword !== formConfirmPassword) {
          loading = false
          formRegisterPasswordError = true
          return
        }
        auth
          .createUserWithEmailAndPassword(formEmail, formPassword)
          .then(data => {
            loading = false
            if (data.user) {
              data.user.updateProfile({ displayName: formDisplayName || '' })
            } else return Promise.reject('unknown_register_error')
          })
          .catch(err => {
            loading = false
            formLoginError = true
            console.error(err)
          })
        break
      default:
        loading = false
        state = 'none'
    }
  }

  function callLoginWithGoogle() {
    resetFlags()
    auth
      .signInWithPopup(googleProvider)
      .then(data => {
        if (!data) return Promise.reject('unknown_login_error')
      })
      .catch(err => {
        console.error(err)
      })
  }
  function callLoginWithMicrosoft() {
    resetFlags()
    auth
      .signInWithPopup(microsoftProvider)
      .then(data => {
        if (!data) return Promise.reject('unknown_login_error')
      })
      .catch(err => {
        console.error(err)
      })
  }
  function callForgetPassword() {
    resetFlags()
    auth
      .sendPasswordResetEmail(formEmail)
      .then(data => {
        formRecoveryMessage = true
      })
      .catch(err => {
        formRecoveryMessageError = true
        console.error(err)
      })
  }

  onMount(() => {})
</script>

<style>
  section {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 320px;
  }
  section > * {
    margin-bottom: 2rem;
  }

  header {
    width: 12rem;
    margin-right: auto;
    margin-left: auto;
  }

  main,
  label {
    display: flex;
    flex-direction: column;
  }

  #auth-buttons {
    margin: 0.5rem 0;
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-self: stretch;
  }

  main > div,
  footer > div {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  main > div {
    justify-content: space-between;
  }

  .alerts {
    color: red;
  }

  .alerts .success {
    color: green;
  }
</style>

<form
  action="/"
  autocomplete="on"
  on:submit|preventDefault|stopPropagation={mainButtonFormHandler}>
  <section>
    <header>
      <img src="/images/logo-mapee-clean.svg" alt="Marca do MAPEE" />
    </header>
    <main>
      <!-- <div
        use:lottieDirective={{ path: 'https://maxst.icons8.com/vue-static/landings/animated-icons/icons/load-from-cloud/load-from-cloud.json' }} /> -->
      {#if state === 'none'}
        <label>
          <span>Email</span>
          <input
            required
            type="email"
            name="email"
            autocomplete="email"
            bind:value={formEmail} />
        </label>
      {:else}
        <div>
          <FLIconButton icon="arrow_back" on:click={() => (state = 'none')} />
          <h3 class="type-h6">{formEmail}</h3>
          <input
            style="display: none"
            type="email"
            name="email"
            bind:value={formEmail} />
        </div>
      {/if}
      {#if state === 'login'}
        <label>
          <span>Senha</span>
          <input
            type="password"
            name="password"
            autocomplete="current-password"
            bind:value={formPassword} />
        </label>
      {/if}
      {#if state === 'register'}
        <label>
          <span>Nome de apresentação</span>
          <input
            type="text"
            name="username"
            autocomplete="username"
            bind:value={formDisplayName} />
        </label>
        <label>
          <span>Senha</span>
          <input
            type="password"
            name="password"
            autocomplete="new-password"
            bind:value={formPassword} />
        </label>
        <label>
          <span>Confirmação de Senha</span>
          <input
            type="password"
            name="confirmPassword"
            autocomplete="off"
            bind:value={formConfirmPassword} />
        </label>
      {/if}
    </main>
    <footer>
      <FLButton type="submit">
        {#if loading}
          <FLIndeterminateLoading />
        {:else if state === 'none'}
          proximo
        {:else if state === 'login'}
          entrar
        {:else if state === 'register'}
          registrar
        {:else if state === 'recover'}recuperar{/if}
      </FLButton>
      {#if state === 'none'}
        <div id="auth-buttons">
          <FLIconButton on:click={callLoginWithGoogle}>
            <img
              aria-hidden="true"
              alt="conta da google"
              crossorigin="anonymous"
              src="https://img.icons8.com/color/24/google-logo.png" />
          </FLIconButton>
          <FLIconButton on:click={callLoginWithMicrosoft}>
            <img
              aria-hidden="true"
              alt="conta da microsoft"
              crossorigin="anonymous"
              src="https://img.icons8.com/color/24/microsoft.png" />
          </FLIconButton>
        </div>
      {/if}
      {#if state === 'login'}
        <FLButton text on:click={callForgetPassword}>
          Esqueceu sua senha?
        </FLButton>
      {/if}

      <div class="alerts">
        {#if formEmailError}
          <p class="type-body">Seu email não é valido</p>
        {/if}
        {#if formLoginError}
          <p class="type-body">Não foi possível realizar o login</p>
        {/if}
        {#if formRegisterError}
          <p class="type-body">Não foi possível registrar seu usuário</p>
        {/if}
        {#if formRegisterPasswordError}
          <p class="type-body">A senha de confirmação está divergindo</p>
        {/if}
        {#if formRecoveryMessage}
          <p class="type-body success">Email de recuperação enviado</p>
        {/if}
        {#if formRecoveryMessageError}
          <p class="type-body">Erro ao enviar email de recuperação</p>
        {/if}
      </div>
    </footer>
  </section>
</form>
